<template>
    <viewer
        :title="'Friends'"
        container-width="60%"
        @closeSidePanel="closeFriends"
    >
        <template #body>
            <div
                v-if="friends.length"
                class="my-4"
            >
                <v-data-table
                    :headers="tableHeader"
                    :items="friends"
                    item-key="id"
                    calculate-widths
                    class="mt-2"
                >
                    <template v-slot:body="{ items }">
                        <tbody class="">
                            <tr
                                v-for="(item, index) in items"
                                :key="index"
                            >
                                <td>
                                    <span class="mb-2">
                                        {{ index + 1 }}
                                    </span>
                                </td>
                                <td>
                                    <span class="mb-2">
                                        {{ $date.shortFormat(item.created_at) }}
                                    </span>
                                </td>
                                <td>
                                    <div class="d-flex align-center">
                                        <client-avatar
                                            :id="item.id"
                                            :firstname="item.firstname"
                                            :lastname="item.lastname"
                                            :client-country="item.country"
                                            :orders-count="item.statistics.orders_total"
                                            avatar-size="35"
                                            class="mr-4"
                                        />
                                        <div class="ml-6">
                                            <p
                                                class="mb-0 font-weight-bold text-decoration-none black--text"
                                                style="cursor: pointer"
                                                @click="goToDetails(item.id)"
                                            >
                                                {{ nameCapitalized(item.firstname) }} {{ nameCapitalized(item.lastname) }}
                                            </p>
                                            <span class="grey--text mb-1 d-flex align-center">{{ item.id }}</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span
                                        v-if="item.statistics.last_order_at"
                                        class="mb-2"
                                    >
                                        {{ $date.shortFormat(item.statistics.last_order_at) }}
                                    </span>
                                    <span v-else> — </span>
                                </td>
                                <td>
                                    <span class="mb-2">
                                        <v-avatar
                                            v-if="item.statistics.orders_total"
                                            size="35"
                                            color="primary"
                                        >
                                            <span style="color: #fff">
                                                {{ item.statistics.orders_total }}
                                            </span>
                                        </v-avatar>
                                        <span v-else>
                                            —
                                        </span>
                                    </span>
                                </td>
                                <td>
                                    <span
                                        v-if="item.statistics.purchases !== '0.00'"
                                        class="mb-2 font-weight-bold"
                                    >
                                        {{ item.statistics.purchases | money }}
                                    </span>
                                    <span v-else> — </span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
            <div
                v-else
                class="d-flex flex-column justify-center align-center"
                style="height: 600px"
            >
                <span>No friends yet</span>
            </div>
        </template>
    </viewer>
</template>

<script>

import filtersMixin from '@/mixins/filtersMixin'
import Viewer from '@/components/SidePanels/Viewer'
import ClientAvatar from '@/components/shared/Avatars/ClientAvatar.vue';

import { nameCapitalized } from '@/helpers/capitalized/index'

export default {
    components: {
        ClientAvatar,
        Viewer
    },
    mixins: [
        filtersMixin
    ],
    props: {
        friends: {
            type: Array,
            required: true
        }
    },
    computed: {
        tableHeader() {
            return [
                {
                    text: 'In-x',
                    sortable: false,
                    value: 'index',
                    width: '10px'
                },
                {
                    text: 'Сreated at',
                    sortable: false,
                    width: '75px'
                },
                {
                    text: 'Client',
                    sortable: false,
                    width: '100px'
                },
                {
                    text: 'Last order at',
                    sortable: false,
                    width: '100px'
                },
                {
                    text: 'Orders total',
                    sortable: false,
                    width: '75px'
                },
                {
                    text: 'Purchases',
                    sortable: false,
                    width: '100px'
                }
            ]
        }
    },
    methods: {
        nameCapitalized,
        closeFriends() {
            this.$emit('closeFriends')
        },
        goToDetails(id) {
            this.$router.push({
                name: 'hr-clients-details',
                params: { id }
            })
        }
    }
}
</script>

<style lang="scss"></style>
