<template>
    <div class="wrapper py-5">
        <v-container
            fluid
            class="white rounded"
        >
            <v-data-table
                v-model="selected"
                :headers="tableHeader"
                :items="getterClientsList"
                item-key="id"
                :server-items-length="getterTotal"
                :loading="getterListLoading"
                hide-default-footer
                calculate-widths
                class="mt-10"
                must-sort
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                @update:options="onSortTable"
            >
                <template #header="{ }">
                    <thead
                        v-if="!mobile"
                        class="v-data-table-header"
                    >
                        <tr>
                            <th
                                v-for="h in tableHeader"
                                :key="h.value"
                            >
                                <template v-if="h.value === 'general'">
                                    <div class="d-flex align-center">
                                        <div class="mr-4">
                                            <p class="mb-0 grey--text">
                                                Clients:
                                            </p>
                                            <p class="mb-0">
                                                <v-progress-circular
                                                    v-if="getterTotalsLoading"
                                                    ref="loader"
                                                    :size="14"
                                                    color="primary"
                                                    indeterminate
                                                />
                                                <b v-else>{{ getterSummary.count | number }}</b>
                                            </p>
                                        </div>
                                        <!--                                        <div class="mr-4">-->
                                        <!--                                            <p class="mb-0 grey&#45;&#45;text">-->
                                        <!--                                                Invited friends:-->
                                        <!--                                            </p>-->
                                        <!--                                            <p class="mb-0">-->
                                        <!--                                                <v-progress-circular-->
                                        <!--                                                    v-if="getterTotalsLoading"-->
                                        <!--                                                    ref="loader"-->
                                        <!--                                                    :size="14"-->
                                        <!--                                                    color="primary"-->
                                        <!--                                                    indeterminate-->
                                        <!--                                                />-->
                                        <!--                                                <b v-else>{{ getterSummary.invited_friends_count | number }}</b>-->
                                        <!--                                            </p>-->
                                        <!--                                        </div>-->
                                        <!--                                        <div class="mr-4">-->
                                        <!--                                            <p class="mb-0 grey&#45;&#45;text">-->
                                        <!--                                                Active friends:-->
                                        <!--                                            </p>-->
                                        <!--                                            <p class="mb-0">-->
                                        <!--                                                <v-progress-circular-->
                                        <!--                                                    v-if="getterTotalsLoading"-->
                                        <!--                                                    ref="loader"-->
                                        <!--                                                    :size="14"-->
                                        <!--                                                    color="primary"-->
                                        <!--                                                    indeterminate-->
                                        <!--                                                />-->
                                        <!--                                                <b v-else>{{ getterSummary.active_friends_count | number }}</b>-->
                                        <!--                                            </p>-->
                                        <!--                                        </div>-->
                                        <!--                                        <div class="mr-4">-->
                                        <!--                                            <p class="mb-0 grey&#45;&#45;text">-->
                                        <!--                                                Total earnings friends:-->
                                        <!--                                            </p>-->
                                        <!--                                            <p class="mb-0">-->
                                        <!--                                                <v-progress-circular-->
                                        <!--                                                    v-if="getterTotalsLoading"-->
                                        <!--                                                    ref="loader"-->
                                        <!--                                                    :size="14"-->
                                        <!--                                                    color="primary"-->
                                        <!--                                                    indeterminate-->
                                        <!--                                                />-->
                                        <!--                                                <b v-else>{{ getterSummary.total_earnings_friends | money }}</b>-->
                                        <!--                                            </p>-->
                                        <!--                                        </div>-->
                                    </div>
                                </template>
                                <template v-if="h.value === 'purchases'">
                                    <div class="d-flex align-center">
                                        <div class="mr-4">
                                            <p class="mb-0 grey--text">
                                                Purchases:
                                            </p>
                                            <p class="mb-0">
                                                <v-progress-circular
                                                    v-if="getterTotalsLoading"
                                                    ref="loader"
                                                    :size="14"
                                                    color="primary"
                                                    indeterminate
                                                />
                                                <b v-else>{{ getterSummary.purchases | money }}</b>
                                            </p>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="h.value === 'account_balance'">
                                    <div class="d-flex align-center">
                                        <div class="mr-4">
                                            <p class="mb-0 grey--text">
                                                Acc. balance:
                                            </p>
                                            <p class="mb-0">
                                                <v-progress-circular
                                                    v-if="getterTotalsLoading"
                                                    ref="loader"
                                                    :size="14"
                                                    color="primary"
                                                    indeterminate
                                                />
                                                <b v-else>{{ getterSummary.account_balance | money }}</b>
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </th>
                        </tr>
                    </thead>
                    <thead v-else>
                        <tr>
                            <th class="d-flex">
                                <div class="d-flex align-center">
                                    <div class="mr-4">
                                        <p class="mb-0 grey--text">
                                            Clients:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="getterTotalsLoading"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ getterSummary.count | number }}</b>
                                        </p>
                                    </div>
                                    <!--                                    <div class="mr-4">-->
                                    <!--                                        <p class="mb-0 grey&#45;&#45;text">-->
                                    <!--                                            Invited friends:-->
                                    <!--                                        </p>-->
                                    <!--                                        <p class="mb-0">-->
                                    <!--                                            <v-progress-circular-->
                                    <!--                                                v-if="getterTotalsLoading"-->
                                    <!--                                                ref="loader"-->
                                    <!--                                                :size="14"-->
                                    <!--                                                color="primary"-->
                                    <!--                                                indeterminate-->
                                    <!--                                            />-->
                                    <!--                                            <b v-else>{{ getterSummary.invited_friends_count | number }}</b>-->
                                    <!--                                        </p>-->
                                    <!--                                    </div>-->
                                    <!--                                    <div class="mr-4">-->
                                    <!--                                        <p class="mb-0 grey&#45;&#45;text">-->
                                    <!--                                            Active friends:-->
                                    <!--                                        </p>-->
                                    <!--                                        <p class="mb-0">-->
                                    <!--                                            <v-progress-circular-->
                                    <!--                                                v-if="getterTotalsLoading"-->
                                    <!--                                                ref="loader"-->
                                    <!--                                                :size="14"-->
                                    <!--                                                color="primary"-->
                                    <!--                                                indeterminate-->
                                    <!--                                            />-->
                                    <!--                                            <b v-else>{{ getterSummary.active_friends_count | number }}</b>-->
                                    <!--                                        </p>-->
                                    <!--                                    </div>-->
                                    <!--                                    <div class="mr-4">-->
                                    <!--                                        <p class="mb-0 grey&#45;&#45;text">-->
                                    <!--                                            Total earnings friends:-->
                                    <!--                                        </p>-->
                                    <!--                                        <p class="mb-0">-->
                                    <!--                                            <v-progress-circular-->
                                    <!--                                                v-if="getterTotalsLoading"-->
                                    <!--                                                ref="loader"-->
                                    <!--                                                :size="14"-->
                                    <!--                                                color="primary"-->
                                    <!--                                                indeterminate-->
                                    <!--                                            />-->
                                    <!--                                            <b v-else>{{ getterSummary.total_earnings_friends | money }}</b>-->
                                    <!--                                        </p>-->
                                    <!--                                    </div>-->
                                </div>
                                <div class="d-flex align-center">
                                    <div class="mr-4">
                                        <p class="mb-0 grey--text">
                                            Purchases:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="getterTotalsLoading"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ getterSummary.purchases | money }}</b>
                                        </p>
                                    </div>
                                </div>
                                <div class="d-flex align-center">
                                    <div class="mr-4">
                                        <p class="mb-0 grey--text">
                                            Acc. balance:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="getterTotalsLoading"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ getterSummary.account_balance | money }}</b>
                                        </p>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }">
                    <table-skeleton
                        v-if="getterListLoading && items.length === 0"
                        :per-page="searchOptions.per_page"
                        :column-count="tableHeaderLength"
                    />
                    <table-loader
                        v-if="getterListLoading"
                    />
                    <tbody>
                        <template v-for="(item, index) in items">
                            <tr
                                :key="index"
                                class="hovered-row"
                                :class="rowClasses(item)"
                                style="cursor: pointer"
                                @mouseenter="selectItem(item.id)"
                                @mouseleave="unSelectItem()"
                            >
                                <!-- Registered -->
                                <td>
                                    <div
                                        class="d-flex align-center"
                                        style="white-space: nowrap"
                                    >
                                        <div>{{ $date.dateFromNow(item.created_at) }}</div>
                                        <information-tooltip>
                                            <span>{{ $date.shortText(item.created_at) }}</span>
                                        </information-tooltip>
                                    </div>
                                </td>
                                <!-- Registered -->
                                <!-- GENERAL -->
                                <td style="position: sticky; left: 0;z-index: 9; background-color: white">
                                    <div class="d-flex flex-column">
                                        <div class="">
                                            <div class="d-flex flex-column">
                                                <div class="d-flex">
                                                    <client-avatar
                                                        :id="item.id"
                                                        access-status="client"
                                                        :firstname="item.firstname"
                                                        :lastname="item.lastname"
                                                        :orders-count="item.statistics.orders.all_orders_count"
                                                        avatar-size="45"
                                                        class=""
                                                    />
                                                    <div class="ml-4">
                                                        <p
                                                            class="mb-0 font-weight-bold text-decoration-none black--text"
                                                            @click="goToDetails(item.id)"
                                                        >
                                                            {{ nameCapitalized(item.firstname) }} {{ nameCapitalized(item.lastname) }}
                                                        </p>
                                                        <span class="grey--text mb-1 d-flex align-center">
                                                            {{ item.id }}
                                                            <v-tooltip
                                                                v-if="item.phone"
                                                                top
                                                                open-on-click
                                                                :open-on-hover="false"
                                                                color="primary"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon
                                                                        color="primary"
                                                                        class="ml-2"
                                                                        size="20"
                                                                        v-bind="attrs"
                                                                        @click="on.click"
                                                                        @blur="on.blur"
                                                                    >
                                                                        mdi-phone
                                                                    </v-icon>
                                                                </template>
                                                                <div>
                                                                    <p class="white--text mb-0 text-body-2">
                                                                        Phone
                                                                    </p>
                                                                    <div class="d-flex">
                                                                        <p class="d-flex align-center text-subtitle-2 font-weight-regular">
                                                                            <copy-to-click-board
                                                                                :copy-string="`(+${item.phone_code}) ${item.phone}`"
                                                                                icon-color="white"
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </v-tooltip>
                                                            <div
                                                                v-if="item.messages_to.length"
                                                                class="ma-1"
                                                            >
                                                                <v-tooltip
                                                                    top
                                                                    color="primary"
                                                                >
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <b
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <v-avatar
                                                                                color="rgba(39, 217, 31, 0.1)"
                                                                                size="20"
                                                                                style="cursor: pointer"
                                                                            >
                                                                                <div class="font-weight-bold text-body-2 success--text">
                                                                                    <span style="font-size: 14px;">{{ item.messages_to.length }}</span>
                                                                                </div>
                                                                            </v-avatar>
                                                                        </b>
                                                                    </template>
                                                                    <div
                                                                        v-for="(message, idx) in item.messages_to"
                                                                        :key="idx"
                                                                        class="d-flex align-center"
                                                                        style="gap: 20px"
                                                                    >
                                                                        <div class="mb-4">
                                                                            <p class="mb-0 text-body-2 font-weight-bold">
                                                                                Created at
                                                                            </p>
                                                                            <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                                                                <span style="font-size: 12px">{{ $date.shortFormat(message.created_at) }}</span>
                                                                            </p>
                                                                        </div>
                                                                        <div class="mb-4">
                                                                            <p class="mb-0 text-body-2 font-weight-bold">
                                                                                Message
                                                                            </p>
                                                                            <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                                                                <span
                                                                                    style="font-size: 12px;
                                                                                    min-width: 100px;
                                                                                    width: 200px "
                                                                                >
                                                                                    {{ message.body }}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </v-tooltip>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-center justify-end"
                                                    style="width: 200px; height: 30px"
                                                >
                                                    <div
                                                        class="show-details-tools"
                                                        :class="{
                                                            'show-details-tools--open': hoveredItem === item.id || openedRow.includes(item.id)
                                                        }"
                                                        style="height: 30px"
                                                    >
                                                        <v-icon
                                                            v-if="openedRow.includes(item.id)"
                                                            @click.stop="openFullRow(item.id)"
                                                        >
                                                            mdi-chevron-up
                                                        </v-icon>
                                                        <v-icon
                                                            v-else
                                                            @click.stop="openFullRow(item.id)"
                                                        >
                                                            mdi-chevron-down
                                                        </v-icon>
                                                        <span>
                                                            <router-link
                                                                :to="{
                                                                    name: 'hr-clients-details',
                                                                    params: { id: item.id },
                                                                }"
                                                                class="white--text tw-rounded tw-px-2 tw-py-1 hover:tw-bg-blue-700"
                                                                style="background-color: #1976d2; font-size: 12px; cursor: pointer"
                                                            >
                                                                view
                                                            </router-link>
                                                        </span>
                                                        <table-rows-menu
                                                            :toolbars="toolbars"
                                                            :item="item"
                                                            @menuActivated="onMenuActivated"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <!-- COUNTRY -->
                                <td>
                                    <div
                                        v-if="item.country"
                                        class="mb-2 d-flex align-center"
                                    >
                                        <v-tooltip
                                            top
                                            color="primary"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    :class="'ml-2 flag-icon flag-input-icon flag-icon-' + item.country.toLowerCase()"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                />
                                            </template>
                                            <span>{{ item.country_nice_name }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else>
                                        -
                                    </div>
                                </td>
                                <!-- referral -->
                                <td>
                                    <div
                                        v-if="item.referral_friend"
                                        class="mb-2 d-flex align-center"
                                    >
                                        <client-avatar
                                            :id="item.referral_friend.id"
                                            :firstname="item.referral_friend.firstname"
                                            :lastname="item.referral_friend.lastname"
                                            :client-country="item.referral_friend.country"
                                            :orders-count="item.referral_friend.statistics.orders.all_orders_count"
                                            avatar-size="45"
                                            class="mr-4"
                                        />
                                    </div>
                                    <div v-else>
                                        -
                                    </div>
                                </td>
                                <!-- purchases -->
                                <td class="">
                                    <span
                                        v-if="item.statistics && item.statistics.money && item.statistics.money.purchases"
                                        class="success--text font-weight-bold"
                                    >
                                        {{ item.statistics.money.purchases | money }}
                                    </span>
                                    <span v-else>
                                        —
                                    </span>
                                </td>
                                <!-- Balance -->
                                <td class="">
                                    <span
                                        v-if="item.statistics && item.statistics.money && item.statistics.money.account_balance"
                                        class="success--text font-weight-bold"
                                    >
                                        {{ item.statistics.money.account_balance | money }}
                                    </span>
                                </td>
                                <!-- Social -->
                                <td>
                                    <div class="">
                                        <v-icon v-if="item.social === 'facebook'">
                                            mdi-facebook
                                        </v-icon>
                                        <v-icon v-else-if="item.social === 'google'">
                                            mdi-google
                                        </v-icon>
                                        <span v-else>
                                            —
                                        </span>
                                    </div>
                                </td>
                                <!-- Social -->
                                <!-- Last login -->
                                <td>
                                    <div
                                        v-if="item.last_login_at"
                                        class="d-flex align-center text-center"
                                        style="white-space: nowrap"
                                    >
                                        <div>{{ $date.dateFromNow(item.last_login_at) }}</div>
                                        <information-tooltip>
                                            <span>
                                                {{ $date.shortText(item.last_login_at) }}
                                            </span>
                                            <span>
                                                {{ item.last_login_ip }}
                                            </span>
                                        </information-tooltip>
                                    </div>
                                    <span v-else>
                                        —
                                    </span>
                                </td>
                                <!-- Last login -->
                                <!-- orders -->
                                <td>
                                    <v-avatar
                                        v-if="item.statistics && item.statistics.orders && item.statistics.orders.all_orders_count"
                                        size="43"
                                        color="primary"
                                    >
                                        <span style="color: #fff">
                                            {{ item.statistics.orders.all_orders_count }}
                                        </span>
                                    </v-avatar>
                                    <span v-else>
                                        —
                                    </span>
                                </td>
                                <!-- orders -->
                                <!-- Last order at -->
                                <td>
                                    <div
                                        v-if="item.last_order_at"
                                        class="d-flex align-center text-center"
                                        style="white-space: nowrap"
                                    >
                                        <div>{{ $date.dateFromNow(item.last_order_at) }}</div>
                                        <information-tooltip>
                                            <span>
                                                {{ $date.shortText(item.last_order_at) }}
                                            </span>
                                        </information-tooltip>
                                    </div>
                                    <span v-else>
                                        —
                                    </span>
                                </td>
                                <!-- Last order at -->
                                <!-- friends -->
                                <td>
                                    <v-avatar
                                        v-if="item.statistics && item.statistics.friends"
                                        size="43"
                                        color="rgba(255, 0, 0, 0.1)"
                                        @click="showActiveFriends(item.friends)"
                                    >
                                        <span style="color: rgb(255, 0, 0)">
                                            {{ item.statistics.friends.active }}
                                        </span>
                                    </v-avatar>
                                    <span v-else>
                                        —
                                    </span>
                                </td>
                                <!-- friends -->
                                <!-- earnings -->
                                <td class="">
                                    <span
                                        v-if="item.statistics && item.statistics.money"
                                        class="success--text font-weight-bold"
                                    >
                                        {{ item.statistics.money.friends_earnings | money }}
                                    </span>
                                    <span v-else>
                                        —
                                    </span>
                                </td>
                                <!-- earnings -->
                                <!-- domain -->
                                <td>
                                    <domain-favicon
                                        :domain="item.domain"
                                    />
                                </td>
                            </tr>
                            <tr
                                :key="`${index}-exdent`"
                                class="exdent-row"
                                :class="extrasRowClasses(item)"
                            >
                                <td
                                    class="client-table__info"
                                    :colspan="tableHeader.length + 1"
                                >
                                    <general-extras
                                        :data="item"
                                        :is-show="openedRow.includes(item.id)"
                                    />
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </template>
            </v-data-table>

            <div
                v-if="!getterClientsList.length && !getterListLoading "
                class="d-flex flex-column align-center w-full mt-5 mb-5"
            >
                <div class="">
                    <img
                        src="@images/svg/no-data.svg"
                        alt=""
                    >
                </div>
                <p class="text-h6">
                    No clients found
                </p>
            </div>

            <pagination-with-perpage
                v-if="!getterTotalsLoading"
                :list-lenght="getterClientsList.length"
                :last-page="getterLastPage"
                :search-options="searchOptions"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onFilterTable"
            />
        </v-container>

        <friend-viewer
            v-if="showFriends"
            :friends="friends"
            @closeFriends="onCloseFriends"
        />
        <component
            :is="modalComponent"
            :title="modal.title"
            :description="modal.description"
            :client="currentItem"
            :clientid="currentItem.id"
            :is-client="true"
            :loading="loadingInModal"
            :current="createTicketPrepend"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </div>
</template>

<script>

import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

import filtersMixin from '@/mixins/filtersMixin'

import { nameCapitalized } from '@/helpers/capitalized/index'

import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'

import ModalAddStoreCredit from '@/components/shared/Modals/ModalAddStoreCredit'
import ModalCreateMailList from '@/components/shared/Modals/ModalCreateMailList'
import ModalCreateSMS from '@/components/shared/Modals/ModalCreateSMS'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalClientEdit from '@/components/shared/Modals/ModalClientEdit'
import ModalCreateTicketClient from '@/components/shared/Modals/ModalCreateTicketClient'
import ClientAvatar from '@/components/shared/Avatars/ClientAvatar'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'
import InformationTooltip from '@/components/shared/InformationTooltip'

import {
    CLIENTS_TABLE_HEADER,
    TOOLBARS_CLIENT
} from '@/constants/Client'

import {
    ACTION_ADD_STORE_CREDIT,
    ACTION_GET_CLIENTS_LIST,
    ACTION_UPDATE_CLIENT,
    ACTION_GET_PRESETS,
    ACTION_GET_CLIENTS_TOTALS,
    ACTION_REFRESH_STATISTIC,
    ACTION_CREATE_SMS
} from '@/store/modules/clients/action-types';

import { ACTION_CREATE_MAIL_LIST } from '@/store/modules/mailing/action-types';

import { CLEAR_CLIENT_LIST } from '@/store/modules/clients/mutation-types';

import { ACTION_CREATE_TICKET } from '@/store/modules/support/action-types';

import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import TableLoader from '@/components/shared/Tables/TableLoader.vue';
import GeneralExtras from '@/components/Clients/GeneralExtras'

import DatePicker from '@/components/shared/DatePicker.vue';
import DatePickerOrders from '@/components/shared/DatePickerOrders.vue';
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard.vue';
import FriendViewer from '@/components/SidePanels/FriendViewer.vue';
import BidsViewer from '@/components/SidePanels/BidsViewer.vue';
import Helper from '@/helpers/functions';

const {
    mapActions: mapClientsActions,
    mapMutations: mapClientsMutations
} = createNamespacedHelpers('clients');

const {
    mapActions: mapAdminActionsSupport
} = createNamespacedHelpers('support');

const {
    mapActions: mapAdminActionsMailing
} = createNamespacedHelpers('mailing');
export default {
    components: {
        BidsViewer,
        FriendViewer,
        CopyToClickBoard,
        DatePickerOrders,
        DatePicker,
        ModalAddStoreCredit,
        ModalClientEdit,
        ClientAvatar,
        DomainFavicon,
        PaginationWithPerpage,
        ModalCreateTicketClient,
        ModalCreateMailList,
        ModalCreateSMS,
        TableSkeleton,
        TableRowsMenu,
        GeneralExtras,
        TableLoader,
        InformationTooltip,
        ModalAlert,
        AvatarRolles
    },
    mixins: [
        filtersMixin
    ],
    metaInfo: {
        title: 'Clients'
    },
    data() {
        return {
            selected: [],
            searchOptions: {
                page: 1,
                per_page: 10,
                sort_by: 'created_at',
                sort_order: 'DESC',
                from: '',
                to: '',
                friendid: this.$route.params.id
            },
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            search_fields: null,
            hoveredItem: {},
            modal: {},
            currentItem: {},
            actionName: '',
            openedRow: [],
            savedScroll: { x: 0, y: 0 },
            isFirstLoad: true,
            loadingInModal: false,
            mobile: false,
            friends: null,
            showFriends: false,
            showPanel: false
        }
    },
    computed: {
        ...mapGetters('clients', [
            'getterClientsList',
            'getterLastPage',
            'getterTotal',
            'getterCurrentPage',
            'getterPresets',
            'getterSummary',
            'getterTotalsLoading',
            'getterListLoading'
        ]),
        tableHeader() {
            return CLIENTS_TABLE_HEADER
        },
        tableHeaderLength() {
            return CLIENTS_TABLE_HEADER.length
        },
        toolbars() {
            return TOOLBARS_CLIENT
        },
        modalComponent() {
            return this.modal?.name || ''
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        sortBy() {
            return this.$route.query.sort_by || 'created_at'
        },
        sortDesc() {
            return this.$route.query.sort_order === 'ASC'
        },
        createTicketPrepend() {
            return {
                domain: this.currentItem.domain,
                client: {
                    id: this.currentItem.id
                }
            }
        }
    },
    watch: {
        showPanel(val) {
            Helper.lockHtml(val)
        }
    },
    async activated() {
        await this.getClientsList()
    },
    beforeRouteLeave(to, from, next) {
        const scrollOffset = { y: window.pageYOffset || document.documentElement.scrollTop, x: 0 }
        this.savedScroll = { ...scrollOffset }
        if (!to.name.includes('client')) {
            this[CLEAR_CLIENT_LIST]()
        }
        next()
    },
    created() {
        if (window.innerWidth <= 599) this.mobile = true
    },
    methods: {
        nameCapitalized,
        ...mapClientsActions({
            ACTION_GET_CLIENTS_LIST,
            ACTION_ADD_STORE_CREDIT,
            ACTION_UPDATE_CLIENT,
            ACTION_GET_PRESETS,
            ACTION_GET_CLIENTS_TOTALS,
            ACTION_REFRESH_STATISTIC,
            ACTION_CREATE_SMS
        }),
        ...mapAdminActionsSupport({
            ACTION_CREATE_TICKET
        }),
        ...mapAdminActionsMailing({
            ACTION_CREATE_MAIL_LIST
        }),
        ...mapClientsMutations({
            CLEAR_CLIENT_LIST
        }),
        openFullRow(id) {
            if (this.openedRow.includes(id)) {
                this.openedRow = this.openedRow.filter((item) => item !== id)
            } else {
                this.openedRow.push(id)
            }
        },
        // HOVER ON TABLE ROW
        selectItem(item) {
            this.hoveredItem = item
        },
        rowClasses(item) {
            return {
                'table-row--selected-once': this.currentItem?.id === item.id,
                'table-row--opened': this.openedRow.includes(item.id)
            }
        },
        unSelectItem() {
            this.hoveredItem = null
        },
        // TABLE LOGIC
        async getClientsList(with_total = true) {
            try {
                const requestArr = [this[ACTION_GET_CLIENTS_LIST]({ ...this.searchOptions, mode: 'list' })]
                if (with_total) {
                    requestArr.push(this[ACTION_GET_CLIENTS_TOTALS]({ ...this.searchOptions, mode: 'count' }))
                }
                await Promise.all(requestArr)
                this.isFirstLoad = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async paginationPageEvent(e) {
            this.searchOptions.page = e
            await this.getClientsList(false)
        },
        async onFilterTable(payload) {
            if (this.isFirstLoad) {
                this.searchOptions = { ...this.searchOptions, ...payload }
            } else {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            }
            await this.getClientsList()
        },
        async onSortTable(e) {
            // eslint-disable-next-line prefer-destructuring
            if (!this.isFirstLoad) {
                this.sort.sort_by = e.sortBy[0] || 'created_at'
                this.sort.sort_order = e.sortDesc[0] ? 'ASC' : 'DESC'
                this.searchOptions = {
                    ...this.searchOptions,
                    ...this.sort,
                    page: 1
                }
                await this.getClientsList()
            }
        },
        // MODALS ACTION
        onMenuActivated(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        showActiveFriends(friends) {
            this.friends = friends
            this.showFriends = true
            this.showPanel = true
        },
        onCloseFriends() {
            this.showFriends = false
            this.showPanel = false
            this.friends = null
        },
        async onConfirmModal(payload) {
            this.loadingInModal = true
            try {
                switch (this.actionName) {
                case 'store_credit':
                    await this.addClientStoreCredit({ ...payload, clientid: this.currentItem.id })
                    break
                case 'edit':
                    await this.editClient({ ...payload })
                    break
                case 'contact_client':
                    await this.contactClient({ ...payload })
                    break
                case 'statistic_refresh':
                    await this.refreshStatistic(payload)
                    break
                case 'create_mail_list':
                    await this.ACTION_CREATE_MAIL_LIST({ ...payload, ...this.searchOptions })
                    break
                case 'create_sms':
                    await this.ACTION_CREATE_SMS({ ...payload, clientid: this.currentItem.id })
                    break
                default:
                    break
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingInModal = false
            }
        },
        async contactClient(payload) {
            await this[ACTION_CREATE_TICKET](payload)
        },
        async addClientStoreCredit(payload) {
            await this[ACTION_ADD_STORE_CREDIT](payload)
        },
        async editClient(payload) {
            await this[ACTION_UPDATE_CLIENT]({ ...payload })
        },
        async refreshStatistic() {
            await this[ACTION_REFRESH_STATISTIC]({ id: this.currentItem.id })
            await this.getClientsList()
        },
        extrasRowClasses(item) {
            return {
                'table-extras-row--opened': this.openedRow.includes(item.id)
            }
        },
        goToDetails(id) {
            this.$router.push({
                name: 'hr-clients-details',
                params: { id }
            })
        }
    }
}
</script>

<style lang="scss">
@media (max-width: 599px) {
    .client-table {
        &__info {
            display: flex;
        }
        &__info-wrapper {
            width: 100%;
        }
    }
}
</style>
