<template>
    <div class="mt-5">
        <the-order-table
            v-model="selected"
            :table-items="getterOrderList"
            :total="getterTotal"
            :current-page="getterCurrentPage"
            :last-page="getterLastPage"
            :loading="getterOrderListLoading"
            :loading_totals="getterTotalsLoading"
            :per_page="searchOptions.per_page"
            :current-item="currentItem"
            :focused-orderid="focusedOrderid"
            @selected="onSelected"
            @paginate="onPaginate"
            @sortTable="onSortTable"
            @actionRow="onActionRow"
            @showBids="onShowBids"
            @showNotes="onShowNotes"
            @showTestimonials="onShowTestimonials"
            @showBatches="onShowBatches"
            @updatePerpage="onFilterTable"
            @showDetails="onShowDetails"
        />
        <toolbar-menu
            v-if="selected.length"
            :toolbars="toolbars"
            @toolbarActivated="onToolbarActivated"
        />

        <component
            :is="modalComponent"
            :title="modal.title"
            :description="modal.description"
            :input-label="modal.inputLabel"
            :current="currentItem"
            :loading="action_loading"
            :allowed-file-types="allowedFileTypes"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />

        <!-- // Bids -->
        <bids-viewer
            v-if="showBids"
            :bids-info="bidsInfo"
            @assignBid="onAssignBid"
            @closeBids="onCloseBids"
            @deleteBid="onDeleteBid"
        />

        <!-- DETAILS -->

        <details-viewer
            v-if="showDetails"
            :order_id="detailsOrderid"
            @closeDetails="onCloseDetails"
        />

        <!-- NOTES -->
        <order-notes-viewer
            v-if="showNotes"
            :order_id="notesOrderid"
            @closeNotes="onCloseNotes"
            @deleteNote="onDeleteNote"
        />

        <!-- TESTIMONIALS -->
        <order-testimonials-viewer
            v-if="showTestimonials"
            :orderid="testimonialsOrderid"
            @closeTestimonials="onCloseTestimonials"
        />

        <!-- Batch viewer -->
        <batch-viewer
            v-if="showBatchPanel"
            :batchid="batchPanelId"
            @closeBatches="onCloseBatches"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import TheOrderTable from '@/components/Orders/OrderTable/TheOrderTable'
import ToolbarMenu from '@/components/shared/Tables/ToolbarMenu'

import Helper from '@/helpers/functions';

import {
    ACTION_ASIGN_WRITER,
    ACTION_CREATE_NOTE,
    ACTION_GET_ORDER_LIST,
    ACTION_GET_ORDER_PRESETS,
    ACTION_GET_ORDER_TOTALS,
    ACTION_ORDER_DEADLINE,
    ACTION_ORDER_DROP,
    ACTION_ORDER_FEE,
    ACTION_ORDER_LIST_LIVE_UPDATE,
    ACTION_ORDER_REVISION,
    ACTION_UPLOAD_ORDER_FILE,
    ACTION_NOTIFY_COMPLETE_ORDER,
    ACTION_NOTIFY_UNPAID_ORDER,
    ACTION_ORDER_CHANGE_STATUS
} from '@/store/modules/orders/action-types';
import {
    ASSIGN_BID,
    DELETE_BID,
    DELETE_NOTE_COUNTER,
    CLEAR_ORDER_LIST
} from '@/store/modules/orders/mutation-types';
import {
    ACTION_CREATE_BOOKMARK
} from '@/store/modules/bookmarks/action-types';
import {
    ALLOWED_FILE_TYPES, MASS_ORDER_TOOLBARS
} from '@/constants/Order'

import {
    ACTION_CREATE_TICKET,
    ACTION_CREATE_TICKET_WRITER
} from '@/store/modules/support/action-types';

// MODALS
import ModalTextarea from '@/components/shared/Modals/ModalTextarea'
import ModalCreateTicketClient from '@/components/shared/Modals/ModalCreateTicketClient'
import ModalCreateTicketWriter from '@/components/shared/Modals/ModalCreateTicketWriter'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalAssign from '@/components/shared/Modals/ModalAssign'
import ModalDeadline from '@/components/shared/Modals/ModalDeadline'
import ModalFee from '@/components/shared/Modals/ModalFee'
import ModalUpload from '@/components/shared/Modals/ModalUpload'
import ModalTransfer from '@/components/shared/Modals/ModalTransfer'
import ModalDropWriter from '@/components/shared/Modals/ModalDropWriter'

import BidsViewer from '@/components/SidePanels/BidsViewer'
import OrderTestimonialsViewer from '@/components/SidePanels/TestimonialsViewer'
import OrderNotesViewer from '@/components/SidePanels/OrderNotesViewer'
import BatchViewer from '@/components/SidePanels/BatchViewer'
import DetailsViewer from '@/components/SidePanels/DetailsViewer.vue';

const {
    mapActions: mapAdminActions,
    mapMutations: mapOrderMutations
} = createNamespacedHelpers('orders');

const {
    mapActions: mapBookmarkActions
} = createNamespacedHelpers('bookmarks');

const {
    mapActions: mapSupportActions
} = createNamespacedHelpers('support');

export default {
    components: {
        TheOrderTable,
        ToolbarMenu,
        // MODALS
        ModalAssign,
        ModalTextarea,
        ModalCreateTicketClient,
        ModalCreateTicketWriter,
        ModalAlert,
        ModalDeadline,
        ModalFee,
        ModalUpload,
        ModalTransfer,
        ModalDropWriter,
        OrderTestimonialsViewer,
        BidsViewer,
        OrderNotesViewer,
        BatchViewer,
        DetailsViewer
    },
    props: {
        clientName: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showPanel: false,
            isFirstLoading: true,
            selected: [],
            currentItem: {},
            actionName: '',
            modal: {},
            action_loading: false,
            searchOptions: {
                page: 1,
                per_page: 10,
                sw_status: '',
                status: '',
                search_for: this.$route.params.id,
                search_by: 'clientid',
                sort_by: 'created_at',
                sort_order: 'DESC',
                domain: '',
                from: '',
                to: '',
                preset: ''
            },
            // bids panel
            showBids: false,
            bidsInfo: null,
            // notes panel
            showNotes: false,
            notesOrderid: null,
            // batch panel
            showBatchPanel: false,
            batchPanelId: null,
            batchOrderid: null,
            // testimonials panel
            showTestimonials: false,
            testimonialsOrderid: null,
            // details panel
            showDetails: false,
            detailsOrderid: null
        }
    },
    computed: {
        ...mapGetters('orders', [
            'getterOrderList',
            'getterTotal',
            'getterCurrentPage',
            'getterOrderListLoading',
            'getterTotalsLoading',
            'getterLastPage'
        ]),
        toolbars() {
            return MASS_ORDER_TOOLBARS
        },
        allowedFileTypes() {
            return ALLOWED_FILE_TYPES
        },
        focusedOrderid() {
            return this.detailsOrderid || this.testimonialsOrderid || this.notesOrderid || this.bidsInfo?.orderid || this.batchOrderid
        },
        modalComponent() {
            return this.modal.name || ''
        }
    },
    watch: {
        showPanel(val) {
            Helper.lockHtml(val)
        }
    },
    metaInfo() {
        return {
            title: `${this.clientName} | Orders | Client`
        }
    },
    async activated() {
        await this.getOrderList()
    },
    methods: {
        ...mapAdminActions({
            ACTION_ASIGN_WRITER,
            ACTION_CREATE_NOTE,
            ACTION_GET_ORDER_LIST,
            ACTION_GET_ORDER_PRESETS,
            ACTION_GET_ORDER_TOTALS,
            ACTION_ORDER_DEADLINE,
            ACTION_ORDER_DROP,
            ACTION_ORDER_FEE,
            ACTION_ORDER_LIST_LIVE_UPDATE,
            ACTION_ORDER_REVISION,
            ACTION_UPLOAD_ORDER_FILE,
            ACTION_NOTIFY_COMPLETE_ORDER,
            ACTION_NOTIFY_UNPAID_ORDER,
            ACTION_ORDER_CHANGE_STATUS
        }),
        ...mapOrderMutations({
            DELETE_BID,
            ASSIGN_BID,
            DELETE_NOTE_COUNTER,
            CLEAR_ORDER_LIST
        }),
        ...mapBookmarkActions({
            ACTION_CREATE_BOOKMARK
        }),
        ...mapSupportActions({
            ACTION_CREATE_TICKET_WRITER,
            ACTION_CREATE_TICKET
        }),
        async getOrderList(with_total = true) {
            try {
                const requestArr = [this[ACTION_GET_ORDER_LIST]({ ...this.searchOptions, mode: 'list' })]
                if (with_total) {
                    requestArr.push(this[ACTION_GET_ORDER_TOTALS]({ ...this.searchOptions, mode: 'count' }))
                }
                await Promise.all(requestArr)
                this.isFirstLoading = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        // MODALS LOGIC
        async onConfirmModal(payload) {
            this.action_loading = true
            try {
                switch (this.actionName) {
                case 'upload_files':
                    await this.uploadFiles(payload)
                    break

                case 'bookmark':
                    await this.createBookmark(payload)
                    break

                case 'bookmark_mass':
                    await this.createBookmarkMass(payload)
                    break

                case 'rate_complete':
                    await this.notifyCompleteOrder(payload)
                    break

                case 'notify_unpaid':
                    await this.notifyUnpaidOrder(payload)
                    break

                case 'assign':
                    await this.assignWriter(payload)
                    break

                case 'Fee':
                    await this.orderFee(payload)
                    break

                case 'deadline':
                    await this.orderDeadline(payload)
                    break

                case 'client':
                    await this.createClientTicket(payload)
                    break

                case 'message':
                    await this.createWriterTicket(payload)
                    break

                case 'mass_assign':
                    await this.assignWritersMass(payload)
                    break

                case 'drop':
                    await this.orderWriterDrop(payload)
                    break
                case 'revision':
                    await this.orderCreateRevision(payload)
                    break

                case 'notes':
                    await this.orderCreateNote(payload)
                    break

                case 'change_status':
                    await this.orderChangeStatus()
                    break

                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.action_loading = false
            }
        },
        onSelected(payload) {
            this.selected = payload
        },
        onToolbarActivated(payload) {
            this.actionName = payload.type
            this.modal = payload.modal
        },
        async onPaginate(page) {
            this.searchOptions.page = page
            window.scrollTo(0, 0);
            await this.getOrderList(false)
        },
        async onSortTable(option) {
            this.searchOptions = {
                ...this.searchOptions,
                ...option,
                page: 1
            }
            await this.getOrderList()
        },
        async onFilterTable(payload) {
            if (this.isFirstContentLoaded === false) {
                this.searchOptions = { ...this.searchOptions, ...payload }
            } else {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            }
            // await this[CLEAR_ORDER_LIST]()
            await this.getOrderList()
            this.isFirstContentLoaded = true
        },
        async updateList(page) {
            this.page = page
            await this[ACTION_GET_ORDER_LIST]({
                search_by: 'clientid', search_for: this.$route.params.id, page: this.page, per_page: 10, sort_by: 'created_at', sort_order: 'DESC'
            })
        },
        async orderWriterDrop(payload) {
            const { orderid } = this.currentItem
            await this[ACTION_ORDER_DROP]({ orderid, details: payload.reason })
        },
        async orderCreateRevision(payload) {
            const { orderid } = this.currentItem
            await this[ACTION_ORDER_REVISION]({ orderid, ...payload })
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        onActionRow(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        },
        async createBookmark(payload) {
            await this[ACTION_CREATE_BOOKMARK]({ ...payload, referenceids: [this.currentItem.orderid] })
        },
        async createBookmarkMass(payload) {
            const referenceids = this.selected.map((item) => item.orderid)
            await this[ACTION_CREATE_BOOKMARK]({ ...payload, referenceids })
        },
        async uploadFiles({ formData, callback }) {
            const { orderid } = this.currentItem
            formData.append('orderid', orderid)
            await this[ACTION_UPLOAD_ORDER_FILE]({
                formData, callback
            })
        },
        async assignWriter(payload) {
            const { orderid } = this.currentItem
            const writer_data = {
                writer_id: payload.sw_id,
                order_id: orderid
            }
            await this[ACTION_ASIGN_WRITER]({ ...writer_data })
        },
        async assignWritersMass(payload) {
            const writer_data = {
                writer_firstname: payload.firstname,
                writer_lastname: payload.lastname,
                writer_field_id: payload.sw_id,
                writer_email: payload.email,
                writer_avatar: payload.avatar,
                sw_id: payload.sw_id
            }
            const orderIds = this.selected.map((item) => item.orderid)
            await this[ACTION_ASIGN_WRITER]({ sw_id: payload.sw_id, orders: [...orderIds], ...writer_data })
        },
        async orderFee(payload) {
            const { orderid } = this.currentItem
            await this[ACTION_ORDER_FEE]({ ...payload, orderid })
        },
        async orderDeadline(payload) {
            const { orderid } = this.currentItem
            await this[ACTION_ORDER_DEADLINE]({ ...payload, orderid })
        },
        async createClientTicket(payload) {
            const { orderid, clientid } = this.currentItem
            await this[ACTION_CREATE_TICKET]({
                clientid,
                orderid,
                status_priority: 'NORMAL',
                subject: 'inquiry',
                msgtype: 'inquiry',
                msgcategory: '',
                msgresolution: '',
                ...payload
            })
        },
        async createWriterTicket(payload) {
            const { orderid, sw_id } = this.currentItem
            await this[ACTION_CREATE_TICKET_WRITER]({
                clientid: sw_id,
                orderid,
                status_priority: 'NORMAL',
                subject: 'inquiry',
                msgtype: 'inquiry',
                ...payload
            })
        },
        async orderCreateNote(payload) {
            const { orderid } = this.currentItem
            await this[ACTION_CREATE_NOTE]({ orderid, ...payload })
        },
        async notifyCompleteOrder() {
            const referenceids = this.selected.map((item) => item.orderid)
            const promises = referenceids.map((item) => this[ACTION_NOTIFY_COMPLETE_ORDER]({ orderid: item }))
            await Promise.all(promises)
        },
        async notifyUnpaidOrder() {
            const referenceids = this.selected.map((item) => item.orderid)
            const promises = referenceids.map((item) => this[ACTION_NOTIFY_UNPAID_ORDER]({ orderid: item }))
            await Promise.all(promises)
        },
        async orderChangeStatus() {
            const { orderid } = this.currentItem
            await this[ACTION_ORDER_CHANGE_STATUS]({ order_id: orderid })
        },
        // BIDS
        onShowBids(info) {
            this.bidsInfo = info
            this.showBids = true
            this.showPanel = true
        },
        onCloseBids() {
            this.showBids = false
            this.showPanel = false
            this.bidsInfo = null
            this.orderDue = null
        },
        onDeleteBid(payload) {
            this[DELETE_BID](payload)
        },
        onAssignBid(bid, orderid) {
            this[ASSIGN_BID]({ ...bid, orderid })
        },

        // DETAILS

        onShowDetails(orderid) {
            this.showDetails = true
            this.showPanel = true
            this.detailsOrderid = orderid
        },

        onCloseDetails() {
            this.showDetails = false
            this.showPanel = false
            this.detailsOrderid = null
        },

        // NOTES
        onShowNotes(orderid) {
            this.showNotes = true
            this.showPanel = true
            this.notesOrderid = orderid
        },
        onShowTestimonials(orderid) {
            this.showTestimonials = true
            this.showPanel = true
            this.testimonialsOrderid = orderid
        },
        onCloseTestimonials() {
            this.showTestimonials = false
            this.showPanel = false
            this.testimonialsOrderid = null
        },
        onCloseNotes() {
            this.showNotes = false
            this.showPanel = false
            this.notesOrderid = null
        },
        onDeleteNote(orderid) {
            this[DELETE_NOTE_COUNTER](orderid)
        },

        // Batches
        onShowBatches(batchid, orderid) {
            this.showBatchPanel = true
            this.batchPanelId = batchid
            this.batchOrderid = orderid
        },
        onCloseBatches() {
            this.showBatchPanel = false
            this.batchPanelId = null
            this.batchOrderid = null
        }
    }
}
</script>

<style>

</style>
