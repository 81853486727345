export const TOOLBARS = [
    {
        name: 'store_credit',
        text: 'Add a store credit',
        modal: {
            name: 'ModalAddStoreCredit',
            success_message: 'The store credit has been added'
        },
        icon: 'mdi-cash-multiple'
    },
    {
        name: 'edit',
        text: 'Edit',
        modal: {
            name: 'ModalClientEdit',
            success_message: 'Client info has been edit'
        },
        icon: 'mdi-pencil'
    },
    {
        name: 'contact_client',
        text: 'Contact client',
        modal: {
            name: 'ModalCreateTicketClient',
            success_message: 'The ticket is created'
        },
        icon: 'mdi-account-circle-outline'
    },
    {
        name: 'create_sms',
        text: 'SMS to direct',
        modal: {
            name: 'ModalCreateSMS',
            success_message: 'SMS has been sent'
        },
        icon: 'mdi-phone'
    },
    {
        name: 'statistic_refresh',
        text: 'Refresh',
        modal: {
            name: 'ModalAlert',
            title: 'Update the data',
            description: 'Do you really want to update the data about the client?',
            success_message: 'The client is updated'
        },
        icon: 'mdi-refresh-auto'
    },
    {
        name: 'client_affiliate',
        text: 'Client affiliate',
        modal: {
            name: 'ModalClientAffiliate',
            success_message: 'Client affiliate created'
        },
        icon: 'mdi-account-multiple-check '
    }
]
