import { TOOLBARS } from './client-toolbars'

export const CLIENTS_TABLE_HEADER = [
    {
        text: 'Registered',
        value: 'created_at',
        sortable: true,
        width: '120px'
    },
    {
        text: 'General',
        value: 'general',
        sortable: false
    },
    {
        text: 'Country',
        value: 'country',
        sortable: true,
        width: '90px'
    },
    {
        text: 'Referral',
        value: 'referral',
        sortable: false,
        width: '90px'
    },
    {
        text: 'Purchases',
        value: 'purchases',
        sortable: true,
        width: '120px'
    },
    {
        text: 'Acc. Balance',
        value: 'account_balance',
        sortable: true,
        width: '120px'
    },
    {
        text: 'Social',
        value: 'social',
        sortable: true,
        width: '70px'
    },
    {
        text: 'Last Login',
        value: 'last_login_at',
        width: '110px',
        sortable: true
    },
    {
        text: 'Orders',
        value: 'orders_total',
        width: '70px',
        sortable: true
    },
    {
        text: 'Last Order At',
        value: 'last_order_at',
        width: '110px',
        sortable: true
    },
    {
        text: 'Active Friends',
        value: 'active_friends',
        width: '70px',
        sortable: true
    },
    {
        text: 'Friend Earnings',
        value: 'total_earnings_friends',
        width: '70px',
        sortable: true
    },
    {
        text: 'Domain',
        sortable: true,
        value: 'domain',
        align: 'center',
        width: '70px'
    }
    // {
    //     text: '',
    //     sortable: false,
    //     width: '55px'
    // }
]

export const TOOLBARS_CLIENT = TOOLBARS
