<template>
    <div v-if="billings.length">
        <batch-card-component
            v-for="(card, index) in billings"
            :key="card.batchid"
            :card="card"
            :index="index"
            :page="page"
            :per-page="per_page"
        />
        <pagination-with-perpage
            :list-lenght="billings.length"
            :last-page="lastPage"
            :search-options="{ page, per_page }"
            :total-visible="7"
            @updatePage="paginationPageEvent"
            @updatePerpage="onUpdatePerpage"
        />
    </div>
    <div
        v-else
        class="d-flex flex-column align-center w-full mt-5 mb-5"
    >
        <div class="">
            <img
                src="@images/svg/no-data.svg"
                alt=""
            >
        </div>
        <p class="text-h6">
            No Billings found
        </p>
    </div>
</template>

<script>

import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage'
import BatchCardComponent from '@/components/Batch/BatchCardComponent';

export default {
    components: {
        PaginationWithPerpage,
        BatchCardComponent
    },
    props: {
        billings: {
            type: Array,
            required: true
        },
        page: {
            type: Number,
            required: true
        },
        per_page: {
            type: Number,
            required: true
        },
        lastPage: {
            type: Number,
            required: true
        }
    },
    methods: {
        paginationPageEvent(e) {
            this.$emit('paginationPageEvent', e)
        },
        onUpdatePerpage(payload) {
            this.$emit('onUpdatePerpage', payload)
        }
    }
}
</script>
