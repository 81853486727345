<template>
    <batch-list-component
        :billings="getterBatchList"
        :page="page"
        :per_page="per_page"
        :last-page="getterLastPage"
        @paginationPageEvent="paginationPageEvent"
        @onUpdatePerpage="onUpdatePerpage"
    />
</template>

<script>

import { createNamespacedHelpers, mapGetters } from 'vuex';

import { eventBus } from '@/helpers/event-bus/'

import {
    ACTION_GET_BATCH_LIST
} from '@/store/modules/batch/action-types';

import BatchListComponent from '@/components/Batch/BatchListComponent';

const {
    mapActions: mapBatchActions
} = createNamespacedHelpers('batch');

export default {
    components: {
        BatchListComponent
    },
    props: {
        clientId: {
            type: String,
            required: true
        },
        clientName: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            page: 1,
            per_page: 10
        }
    },
    metaInfo() {
        return {
            title: `${this.clientName} | Billing | Client`
        }
    },

    computed: {
        ...mapGetters('batch', [
            'getterBatchList',
            'getterLastPage'
        ])
    },
    activated() {
        this.getListBatch()
    },
    methods: {
        ...mapBatchActions({
            ACTION_GET_BATCH_LIST
        }),
        async getListBatch() {
            try {
                await this[ACTION_GET_BATCH_LIST]({
                    page: this.page,
                    per_page: this.per_page,
                    search_for: this.clientId,
                    search_by: 'clientid'
                })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async paginationPageEvent(e) {
            this.page = e
            await this.getListBatch()
        },
        async onUpdatePerpage(payload) {
            this.page = payload.page
            this.per_page = payload.per_page
            await this.getListBatch()
        }
    }
}
</script>

<style>

</style>
