<template>
    <div class="client-table__info-wrapper">
        <v-expand-transition>
            <div v-show="isShow">
                <!-- Finance -->
                <div class="extras-row green lighten-5">
                    <!-- row title -->
                    <div class="d-flex align-center extras-row_col">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-contacts-outline
                            </v-icon>
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Contacts
                            </span>
                        </div>
                    </div>
                    <!-- Payment method -->
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Phone:
                            </p>
                            <p
                                v-if="data.phone_code"
                                class="mb-0 text-body-2"
                            >
                                {{ data.phone_code }} {{ data.phone }}
                            </p>
                            <p v-else>
                                -
                            </p>
                        </div>
                    </div>

                    <!-- ordered services -->
                    <div class="extras-row_col">
                        <div class="flex">
                            <span class="grey--text">
                                Email:
                            </span>
                            <p
                                v-if="data.email"
                                class="mb-0 text-body-2"
                            >
                                {{ data.email }}
                            </p>
                            <p v-else>
                                -
                            </p>
                        </div>
                    </div>
                    <!-- utm -->
                    <div class="extras-row_col">
                        <div class="flex">
                            <span class="grey--text">
                                UTM:
                            </span>
                            <div
                                v-if="data.utm"
                                class="mb-2 d-flex flex-column"
                            >
                                <v-tooltip
                                    v-if="data.utm.utm_campaign"
                                    top
                                    color="primary"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            class="info--text font-weight-medium"
                                            v-on="on"
                                        >
                                            Campaign
                                        </span>
                                    </template>
                                    <span>{{ data.utm.utm_campaign }}</span>
                                </v-tooltip>
                                <v-tooltip
                                    v-if="data.utm.utm_source"
                                    top
                                    color="primary"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            class="info--text font-weight-medium"
                                            v-on="on"
                                        >
                                            Source
                                        </span>
                                    </template>
                                    <span>{{ data.utm.utm_source }}</span>
                                </v-tooltip>
                                <v-tooltip
                                    v-if="data.utm.utm_medium"
                                    top
                                    color="primary"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            class="info--text font-weight-medium"
                                            v-on="on"
                                        >
                                            Medium
                                        </span>
                                    </template>
                                    <span>{{ data.utm.utm_medium }}</span>
                                </v-tooltip>
                            </div>
                            <div v-else>
                                -
                            </div>
                        </div>
                    </div>
                    <!-- Loyalty discount -->
                    <div class="extras-row_col">
                        <div class="flex">
                            <span class="grey--text">
                                Loyalty discount:
                            </span>
                            <p
                                v-if="data.statistics && data.statistics.money && data.statistics.money.account_balance"
                                class="mb-0 text-body-2"
                            >
                                {{ data.statistics.money.loyalty_discount_rate | percent }}
                            </p>
                            <p v-else>
                                -
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>

import filtersMixin from '@/mixins/filtersMixin'

export default {
    mixins: [
        filtersMixin
    ],
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: false,
            default: 1
        },
        isShow: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss">
    .truncate-text{
        display: inline-block;
        width:max-content;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
