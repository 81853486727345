<template>
    <modal
        :show-modal="showModal"
        :loading="loading"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Client affiliate</span>
        </template>
        <template #body>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-row>
                    <v-col cols="12">
                        <v-checkbox
                            v-model="is_active"
                            hide-details
                            label="Is active"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model.number="rate"
                            label="Rate %"
                            outlined
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model.number="min_payout "
                            color="accent"
                            label="Min payout "
                            hide-details
                            outlined
                        />
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </modal>
</template>

<script>

import Modal from '@/components/shared/Modals/Modal'
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const priceMask = createNumberMask({
    prefix: '$',
    allowDecimal: true
})

export default {
    components: {
        Modal
    },
    props: {
        clientid: {
            type: [Number, String],
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        client: {
            type: Object
        }
    },
    data() {
        return {
            valid: true,
            priceMask,
            showModal: true,
            is_active: this.client?.affiliate?.is_active || false,
            rate: this.client?.affiliate?.rate || null,
            min_payout: this.client?.affiliate?.min_payout || null
        }
    },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.validate()
            if (this.valid) {
                const {
                    clientid, rate, min_payout, is_active
                } = this
                this.$emit('confirmModal', {
                    client_id: clientid, rate, min_payout, is_active
                })
            }
        }
    }
}

</script>
