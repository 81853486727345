<template>
    <tickets-list-component
        ref="list"
        :items="getterSupportList"
        :last-page="getterLastPage"
        :order="createTicketPrepend"
        :is-client="true"
        :list-loading="getterSupportLoading"
        @update="updateList"
        @updatePerpage="onUpdatePerpage"
        @bookmark="createBookmark"
        @close="closeTicket"
        @send="onActionChat"
        @createTicket="onCreateTicket"
    />
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';

import TicketsListComponent from '@/components/shared/TicketsListComponent'

import {
    ACTION_CLOSE_TICKET,
    ACTION_GET_SUPPORT_LIST,
    ACTION_SEND_MESSAGE
} from '@/store/modules/support/action-types';

import {
    ACTION_CREATE_BOOKMARK
} from '@/store/modules/bookmarks/action-types';

import { eventBus } from '@/helpers/event-bus/'

const {
    mapActions: mapBookmarkActions
} = createNamespacedHelpers('bookmarks');

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('support');

export default {
    components: {
        TicketsListComponent
    },
    props: {
        clientName: {
            type: String,
            required: true
        },
        createTicketPrepend: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo'
        ]),
        ...mapGetters('support', [
            'getterSupportList',
            'getterLastPage',
            'getterSupportLoading'
        ])
    },
    metaInfo() {
        return {
            title: `${this.clientName} | Tickets | Client`
        }
    },
    async activated() {
        await this[ACTION_GET_SUPPORT_LIST]({
            search_by: 'respondent_id', search_for: this.$route.params.id, page: 1, mode: 'list'
        })
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_SUPPORT_LIST,
            ACTION_SEND_MESSAGE,
            ACTION_CLOSE_TICKET
        }),
        ...mapBookmarkActions({
            ACTION_CREATE_BOOKMARK
        }),
        async updateList({ page, per_page }) {
            await this[ACTION_GET_SUPPORT_LIST]({
                search_by: 'respondent_id', search_for: this.$route.params.id, page, per_page, mode: 'list'
            })
        },
        async onUpdatePerpage({ page, per_page }) {
            await this[ACTION_GET_SUPPORT_LIST]({
                search_by: 'respondent_id', search_for: this.$route.params.id, page, per_page, mode: 'list'
            })
        },
        async createBookmark(payload) {
            try {
                await this[ACTION_CREATE_BOOKMARK](payload)
                eventBus.$emit('showSnackBar', 'The ticket(s) is successfully added to bookmarks.', 'success')
                this.$refs.list.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async closeTicket(payload) {
            try {
                await this[ACTION_CLOSE_TICKET](payload)
                this.$refs.list.onCloseModal()
                eventBus.$emit('showSnackBar', 'success', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onActionChat(payload) {
            try {
                await this[ACTION_SEND_MESSAGE](payload)
                eventBus.$emit('showSnackBar', 'Message is sent', 'success')
                this.$refs.list.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        onCreateTicket() {
            this.$emit('createTicket')
        }
    }
}
</script>

<style>

</style>
