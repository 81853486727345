<template>
    <v-hover>
        <template v-slot:default="{ hover }">
            <v-card
                :elevation="hover ? 6 : 2"
                class="px-5 py-5 my-5"
                min-width="100%"
                outlined
                hover
            >
                <v-row>
                    <v-col
                        cols="12"
                        class="d-flex align-center"
                    >
                        <span
                            v-if="index + 1"
                            class="text-subtitle-1 grey--text mr-2"
                        >
                            {{ (perPage * (page - 1)) + index + 1 }}.
                        </span>
                        <p
                            class="black--text text-decoration-none mb-0"
                        >
                            <span class="text-subtitle-1">
                                {{ card.orders.length }} orders in {{ card.batchid }}
                            </span>
                        </p>
                        <!-- CLIENT STATUS -->
                        <v-chip
                            class="my-0 text-body-2 mx-2"
                            :color="statusBgColor(card.status)"
                            :text-color="statusTextColor(card.status)"
                        >
                            {{ statusText(card.status) }}
                        </v-chip>

                        <span class="grey--text ml-5">{{ $date.relativeTimeFromNow(card.created_at) }}</span>

                        <span class="black--text font-weight-bold ml-5">${{ card.total }}</span>

                        <div class="ml-auto">
                            <div
                                class="d-flex align-center justify-end"
                                style="cursor: pointer"
                                @click="toggleCard(card.batchid)"
                            >
                                <v-icon v-if="showDetails.includes(card.batchid)">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else>
                                    mdi-chevron-down
                                </v-icon>
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <div v-if="showDetails.includes(card.batchid)">
                        <v-divider class="my-5" />
                        <v-row>
                            <v-col>
                                <p class="grey--text mb-0">
                                    In {{ $date.fromWithText(card.created_at) }}
                                </p>
                                <p class="mb-0 d-flex align-center">
                                    <v-icon
                                        class="mr-2"
                                        color="primary"
                                    >
                                        mdi-clock-outline
                                    </v-icon>
                                    {{ $date.shortText(card.created_at) }}
                                </p>
                            </v-col>
                            <v-col>
                                <p class="mb-0">
                                    Orders
                                </p>
                                <p class="mb-0 d-flex align-center">
                                    <v-icon
                                        class="mr-2"
                                        color="primary"
                                    >
                                        mdi-cart-variant
                                    </v-icon>
                                    {{ card.orders.length }}
                                </p>
                            </v-col>
                            <v-col>
                                <p class="grey--text mb-0">
                                    Batch ID
                                </p>
                                <p class="mb-0 d-flex align-center">
                                    <v-icon
                                        class="mr-2"
                                        color="primary"
                                    >
                                        mdi-credit-card-multiple-outline
                                    </v-icon>
                                    {{ card.batchid }}
                                </p>
                            </v-col>
                            <v-col>
                                <p class="grey--text mb-0">
                                    Total
                                </p>
                                <p class="mb-0 d-flex align-center">
                                    <v-icon
                                        class="mr-2"
                                        color="primary"
                                    >
                                        mdi-credit-card-outline
                                    </v-icon>
                                    ${{ card.total }}
                                </p>
                            </v-col>
                            <v-col>
                                <p class="grey--text mb-0">
                                    Used Store Credit
                                </p>
                                <p class="mb-0 d-flex align-center">
                                    <v-icon
                                        class="mr-2"
                                        color="primary"
                                    >
                                        mdi-credit-card-outline
                                    </v-icon>
                                    ${{ card.store_credit }}
                                </p>
                            </v-col>
                        </v-row>
                        <v-divider class="my-5" />
                        <v-row>
                            <batch-card-orders-components
                                v-for="(order, index2) in card.orders"
                                :key="order.orderid"
                                :index="index2"
                                :order="order"
                            />
                        </v-row>
                    </div>
                </v-expand-transition>
            </v-card>
        </template>
    </v-hover>
</template>

<script>

import { statusBgColor, statusText, statusTextColor } from '@/helpers/order/statusHelper'
import BatchCardOrdersComponents from '@/components/Batch/BatchCardOrdersComponents';

export default {
    components: {
        BatchCardOrdersComponents
    },
    props: {
        card: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        page: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            showDetails: ['']
        }
    },
    methods: {
        statusText,
        statusBgColor,
        statusTextColor,
        toggleCard(id) {
            if (this.showDetails.includes(id)) {
                const index = this.showDetails.findIndex((item) => item.id === id)
                this.showDetails.splice(index, 1)
            } else {
                this.showDetails.push(id)
            }
        }
    }
}
</script>
