<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Edit the client</span>
        </template>
        <template #body>
            <v-form>
                <v-row>
                    <v-col
                        v-for="(value, field) in formdata"
                        :key="field"
                        cols="6"
                    >
                        <v-text-field
                            v-if="typeof value === 'string' || typeof value === 'number'"
                            :id="field"
                            v-model="formdata[field]"
                            outlined
                            hide-details
                            :name="field"
                            :label="field"
                        />
                        <v-checkbox
                            v-else
                            v-model="formdata[field]"
                            hide-details
                            :label="field"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </modal>
</template>

<script>
import Api from '@/helpers/api/index.js'

import Modal from '@/components/shared/Modals/Modal'

export default {
    components: {
        Modal
    },
    props: {
        client: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showModal: true,
            formdata: {
                id: '',
                friendid: '',
                tz: '',
                domain: '',
                fullname: '',
                firstname: '',
                lastname: '',
                email: '',
                email2: '',
                password: '',
                phone_code: '',
                phone: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                region: '',
                country: '',
                ip: '',
                chk_calls: false,
                chk_sms_notifications: false,
                chk_email_notifications: false,
                chk_email_marketing: false,
                chk_hours: false,
                chk_send_sms: false
            }
        }
    },
    computed: {
        clientId() {
            return this.client?.id || this.$route.params.id
        }
    },
    async created() {
        await this.getClientData()
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', { ...this.formdata })
        },
        async getClientData() {
            const { data } = await Api.get('/api/client/fetch-client', { id: this.clientId })
            this.formdata = this.merge_options(this.formdata, data);
        },
        merge_options(obj1, obj2) {
            const copy = { ...obj1 }
            return Object.entries(copy).reduce((acc, curr) => {
                if (typeof curr[1] === 'boolean') {
                    acc[curr[0]] = Boolean(obj2[curr[0]])
                    return acc
                }
                acc[curr[0]] = obj2[curr[0]] || ''
                return acc
            }, {})
        }
    }
}
</script>

<style>

</style>
