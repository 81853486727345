var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"client-table__info-wrapper"},[_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}]},[_c('div',{staticClass:"extras-row green lighten-5"},[_c('div',{staticClass:"d-flex align-center extras-row_col"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(" mdi-contacts-outline ")]),_c('span',{staticClass:"font-weight-bold text-uppercase text-subtitle-2"},[_vm._v(" Contacts ")])],1)]),_c('div',{staticClass:"extras-row_col"},[_c('div',[_c('p',{staticClass:"grey--text mb-0 text-body-2"},[_vm._v(" Phone: ")]),(_vm.data.phone_code)?_c('p',{staticClass:"mb-0 text-body-2"},[_vm._v(" "+_vm._s(_vm.data.phone_code)+" "+_vm._s(_vm.data.phone)+" ")]):_c('p',[_vm._v(" - ")])])]),_c('div',{staticClass:"extras-row_col"},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"grey--text"},[_vm._v(" Email: ")]),(_vm.data.email)?_c('p',{staticClass:"mb-0 text-body-2"},[_vm._v(" "+_vm._s(_vm.data.email)+" ")]):_c('p',[_vm._v(" - ")])])]),_c('div',{staticClass:"extras-row_col"},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"grey--text"},[_vm._v(" UTM: ")]),(_vm.data.utm)?_c('div',{staticClass:"mb-2 d-flex flex-column"},[(_vm.data.utm.utm_campaign)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"info--text font-weight-medium"},'span',attrs,false),on),[_vm._v(" Campaign ")])]}}],null,false,1476885776)},[_c('span',[_vm._v(_vm._s(_vm.data.utm.utm_campaign))])]):_vm._e(),(_vm.data.utm.utm_source)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"info--text font-weight-medium"},'span',attrs,false),on),[_vm._v(" Source ")])]}}],null,false,2139511443)},[_c('span',[_vm._v(_vm._s(_vm.data.utm.utm_source))])]):_vm._e(),(_vm.data.utm.utm_medium)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"info--text font-weight-medium"},'span',attrs,false),on),[_vm._v(" Medium ")])]}}],null,false,1428096179)},[_c('span',[_vm._v(_vm._s(_vm.data.utm.utm_medium))])]):_vm._e()],1):_c('div',[_vm._v(" - ")])])]),_c('div',{staticClass:"extras-row_col"},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"grey--text"},[_vm._v(" Loyalty discount: ")]),(_vm.data.statistics && _vm.data.statistics.money && _vm.data.statistics.money.account_balance)?_c('p',{staticClass:"mb-0 text-body-2"},[_vm._v(" "+_vm._s(_vm._f("percent")(_vm.data.statistics.money.loyalty_discount_rate))+" ")]):_c('p',[_vm._v(" - ")])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }