<template>
    <modal
        :show-modal="showModal"
        :loading="loading"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Create mail list</span>
        </template>
        <template #body>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="name"
                            label="Name"
                            outlined
                            hide-details
                            :rules="[v => !!v || 'Name is required']"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            v-model="description"
                            outlined
                            color="accent"
                            label="description"
                            hide-details
                            required
                            :rules="[v => !!v || 'Description is required']"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </modal>
</template>

<script>

import Modal from '@/components/shared/Modals/Modal'

export default {
    components: {
        Modal
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            valid: true,
            showModal: true,
            name: '',
            description: ''
        }
    },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.validate()
            if (this.valid) {
                const {
                    name, description
                } = this
                this.$emit('confirmModal', {
                    name, description
                })
            }
        }
    }
}
</script>

<style>

</style>
