<template>
    <div class="d-flex flex-nowrap">
        <v-col class="pl-0 py-0">
            <v-select
                v-show="selected_btn === 'SET'"
                v-model="selected_date"
                :items="periodsList"
                label="Period"
                outlined
                hide-details
                @change="selectedPeriod()"
            />
            <v-menu
                ref="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
                hide-details
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-show="selected_btn === 'CUSTOM'"
                        v-model="dateRangeText"
                        label="Period"
                        prepend-inner-icon="mdi-calendar"
                        outlined
                        v-bind="attrs"
                        hide-details
                        v-on="on"
                        @keyup.enter="inputData"
                    />
                </template>
                <v-date-picker
                    v-model="date"
                    range
                    @click:date="dateRangeText = date.join('~')"
                >
                    <v-spacer />
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="customPeriod(date)"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>
        </v-col>
        <v-col class="flex-grow-0 px-0 py-0">
            <v-btn-toggle
                v-model="selected_btn"
                borderless
                color="primary"
                mandatory
                hide-details
                @change="clearData()"
            >
                <v-btn
                    value="SET"
                    width="107px"
                    height="56px"
                    class="my-0"
                >
                    SET
                </v-btn>
                <v-btn
                    value="CUSTOM"
                    height="56px"
                    class="my-0"
                >
                    CUSTOM
                </v-btn>
            </v-btn-toggle>
        </v-col>
    </div>
</template>
<script>
import dayjs from 'dayjs'
import { PERIODS_LIST } from '@/constants/Periods'

export default {
    props: {
        searchOptions: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selected_btn: 'SET',
            selected_date: '',
            date: [],
            last_order_from: '',
            last_order_to: '',
            dateRangeText: '',
            viewDate: ''
        }
    },
    computed: {
        // dateRangeText() {
        //     return this.date.join('—')
        // },
        periodsList() {
            return PERIODS_LIST
        }
    },
    watch: {
        searchOptions: {
            handler(val) {
                if (val.last_order_from.length === 0) {
                    this.clearData()
                }
            },
            deep: true
        }
    },
    created() {
        if (this.searchOptions.last_order_from) {
            this.selected_btn = 'CUSTOM'
            this.date.push(this.searchOptions.last_order_from, this.searchOptions.last_order_to)
        }
    },
    methods: {
        clearData() {
            this.selected_date = ''
            this.date = []
            this.dateRangeText = ''
            this.last_order_from = ''
            this.last_order_to = ''
        },
        selectedPeriod() {
            switch (this.selected_date) {
            case 'today':
                this.last_order_from = dayjs().startOf('day').format('YYYY-MM-DD')
                this.last_order_to = dayjs().startOf('day').format('YYYY-MM-DD')
                break
            case 'yesterday':
                this.last_order_from = dayjs().add(-1, 'day').format('YYYY-MM-DD')
                this.last_order_to = dayjs().add(-1, 'day').format('YYYY-MM-DD')
                break
            case 'seven_days':
                this.last_order_from = dayjs().add(-7, 'day').format('YYYY-MM-DD')
                this.last_order_to = dayjs().startOf('day').format('YYYY-MM-DD')
                break
            case 'thirty_days':
                this.from = dayjs().add(-30, 'day').format('YYYY-MM-DD')
                this.last_order_to = dayjs().startOf('day').format('YYYY-MM-DD')
                break
            case 'this_month':
                this.last_order_from = dayjs().startOf('month').format('YYYY-MM-DD')
                this.last_order_to = dayjs().startOf('day').format('YYYY-MM-DD')
                break
            case 'last_month':
                this.last_order_from = dayjs().add(-1, 'month').startOf('month').format('YYYY-MM-DD')
                this.last_order_to = dayjs().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
                break
            default:
                this.last_order_from = ''
                this.last_order_to = ''
            }
            this.updatePeriod()
        },
        inputData() {
            const newDate = this.dateRangeText.split('~')
            this.date = newDate
        },
        customPeriod(date) {
            this.$refs.menu.save(date)
            const [last_order_from, last_order_to] = this.date
            if (dayjs(this.date[0]).valueOf() < dayjs(this.date[1]).valueOf()) {
                this.last_order_from = last_order_from
                this.last_order_to = last_order_to
                this.updatePeriod()
                return
            }
            this.last_order_from = last_order_to
            this.last_order_to = last_order_from
            this.updatePeriod()
        },
        updatePeriod() {
            this.$emit('updatePeriod', {
                last_order_from: this.last_order_from,
                last_order_to: this.last_order_to
            })
        }
    }
}
</script>
