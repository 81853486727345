<template>
    <v-col cols="12">
        <div class="d-flex black--text">
            <span class="mr-4">
                {{ index + 1 }}.
            </span>
            <span class="mr-4">
                {{ order.title || 'No title' }}
            </span>
            <router-link
                :to="{
                    path: `/orders/${order.orderid}`
                }"
                class="black--text text-decoration-none"
            >
                {{ order.orderid }}
            </router-link>
            <div class="ml-auto">
                <span>
                    ${{ order.total }}
                </span>
            </div>
        </div>
    </v-col>
</template>

<script>

export default {
    props: {
        order: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    }
}

</script>
