<template>
    <div
        v-if="client"
        class="wrapper py-5 mb-15"
    >
        <v-card
            elevation="2"
            class="px-5 py-5"
        >
            <div class="header-client">
                <div class="d-flex align-center w-full mr-4">
                    <avatar-rolles
                        access-status="client"
                        :firstname="client.firstname"
                        :lastname="client.lastname"
                        avatar-size="75"
                    />
                    <div class="ml-2">
                        <p class="font-weight-bold mb-0">
                            {{ nameCapitalized(client.firstname) }}
                            {{ nameCapitalized(client.lastname) }}
                        </p>
                        <p class="grey--text mb-0 d-flex align-center">
                            {{ client.id }}
                            {{ client.country && ',' }}
                            <span :class="'ml-1 flag-icon flag-input-icon flag-icon-' + getCountre" />
                        </p>
                    </div>
                </div>

                <div class="d-flex client-card">
                    <div
                        v-if="client.statistics_parsed.friends.active"
                        class="d-flex flex-column"
                    >
                        <p class="grey--text mb-0">
                            Friend:
                        </p>
                        <div class="d-flex align-center flex-grow-1">
                            <v-avatar
                                v-if="client.statistics_parsed.friends.active"
                                size="43"
                                color="rgba(255, 0, 0, 0.1)"
                            >
                                <span style="color: rgb(255, 0, 0)">
                                    {{ client.statistics_parsed.friends.active }}
                                </span>
                            </v-avatar>
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <p class="grey--text mb-0">
                            Domain:
                        </p>
                        <div class="d-flex align-center flex-grow-1">
                            <domain-favicon
                                class="mr-2"
                                :domain="client.domain"
                            />
                        </div>
                    </div>
                    <div
                        v-if="client.statistics_parsed"
                        class="d-flex flex-column"
                    >
                        <p class="grey--text mb-0">
                            Status:
                        </p>
                        <div class="my-auto">
                            <v-chip
                                class="text-body-2"
                                :color="`${client.statistics_parsed.money.purchases > 0 ? 'primary' : 'green'}`"
                                text-color="white"
                            >
                                {{ client.statistics_parsed.money.purchases > 0 ? 'Paid' : 'New' }}
                            </v-chip>
                        </div>
                    </div>
                    <div
                        v-if="client.statistics_parsed"
                        class="d-flex flex-column"
                    >
                        <p
                            class="grey--text mb-0"
                            style="white-space: nowrap;"
                        >
                            Loyalty discount:
                        </p>
                        <p class="d-flex text-subtitle-1 price--text mb-0 align-center flex-grow-1">
                            {{ client.statistics_parsed.money.loyalty_discount_rate | percent }}
                        </p>
                    </div>
                    <div
                        v-if="client.statistics_parsed"
                        class="d-flex flex-column"
                    >
                        <p
                            class="grey--text mb-0"
                            style="white-space: nowrap;"
                        >
                            Money spent:
                        </p>
                        <p class="d-flex text-subtitle-1 price--text mb-0 align-center flex-grow-1">
                            {{ client.statistics_parsed.money.purchases | money }}
                        </p>
                    </div>
                    <div
                        v-if="client.statistics_parsed"
                        class="d-flex flex-column"
                    >
                        <p class="grey--text mb-0">
                            Balance:
                        </p>
                        <p class="d-flex text-subtitle-1 price--text mb-0 align-center flex-grow-1">
                            {{ client.statistics_parsed.money.account_balance | money }}
                        </p>
                    </div>
                    <table-rows-menu
                        :toolbars="toolbars"
                        class="mt-2"
                        @menuActivated="onToolbarActivated"
                    />
                </div>
            </div>
        </v-card>

        <v-card
            elevation="2"
            class="px-0 py-0 mt-10 d-flex"
            style="overflow-x: auto;"
        >
            <div class="extras-row flex-column">
                <!-- row title -->
                <div class="d-flex align-center extras-row_col extras-row_col-title">
                    <div class="d-flex align-center">
                        <v-icon class="mr-4">
                            mdi-calendar-week
                        </v-icon>
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            Dates
                        </span>
                    </div>
                </div>

                <!-- Last login -->
                <div class="extras-row_col">
                    <div>
                        <p class="grey--text mb-0 text-body-2">
                            Last login:
                        </p>
                        <p
                            v-if="client.last_session"
                            class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        >
                            {{ $date.dateFromNow(client.last_session.created_at) }}
                            <information-tooltip>
                                <div class="mb-1">
                                    <p class="mb-0 text-body-6">
                                        Last session:
                                    </p>
                                    <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                        {{ $date.humanizeDate(client.last_session.created_at) }}
                                    </p>
                                </div>
                            </information-tooltip>
                        </p>
                        <p v-else>
                            -
                        </p>
                    </div>
                </div>

                <!-- Applied -->
                <div class="extras-row_col">
                    <div>
                        <p class="grey--text mb-0 text-body-2">
                            Applied:
                        </p>
                        <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                            {{ $date.dateFromNow(client.created_at) }}
                            <information-tooltip>
                                <div class="mb-1">
                                    <p class="mb-0 text-body-6">
                                        Created:
                                    </p>
                                    <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                        {{ $date.humanizeDate(client.created_at) }}
                                    </p>
                                </div>
                            </information-tooltip>
                        </p>
                    </div>
                </div>

                <!-- Last HTTP request -->
                <div class="extras-row_col">
                    <div>
                        <p class="grey--text mb-0 text-body-2">
                            Last HTTP request:
                        </p>
                        <p
                            v-if="client.last_session"
                            class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        >
                            {{ $date.dateFromNow(client.last_session.updated_at) }}
                            <information-tooltip>
                                <div class="mb-1">
                                    <p class="mb-0 text-body-6">
                                        Last HTTP request:
                                    </p>
                                    <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                        {{ $date.humanizeDate(client.last_session.updated_at) }}
                                    </p>
                                </div>
                            </information-tooltip>
                        </p>
                        <p v-else>
                            -
                        </p>
                        <div
                            v-if="client.last_session"
                            class="d-flex align-center"
                            style="white-space: nowrap"
                        >
                            <p>(IP: {{ client.last_session.ip }})</p>
                            <p class="grey--text d-flex align-center">
                                <span :class="'ml-1 flag-icon flag-input-icon flag-icon-' + getCountryLastSession" />
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="extras-row flex-column">
                <!-- row title -->
                <div class="d-flex align-center extras-row_col extras-row_col-title">
                    <div class="d-flex align-center">
                        <v-icon class="mr-4">
                            mdi-contacts-outline
                        </v-icon>
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            Contacts
                        </span>
                    </div>
                </div>

                <!-- Phone -->
                <div class="extras-row_col">
                    <div>
                        <p class="grey--text mb-0 text-body-2">
                            Phone:
                        </p>
                        <p
                            v-if="client.phone_code"
                            class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        >
                            <span>{{ client.phone_code }} {{ client.phone }}</span>
                            <v-tooltip
                                v-if="client.phone_verified_at"
                                top
                                color="primary"
                                class="ml-2"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="#59C74D"
                                        class="ml-1 information"
                                        style="cursor: pointer"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-check-circle
                                    </v-icon>
                                </template>
                                <div class="mb-1">
                                    <p
                                        class="mb-0"
                                        style="font-size: 16px"
                                    >
                                        Verified:
                                    </p>
                                    <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                        {{ $date.humanizeDate(client.phone_verified_at) }}
                                    </p>
                                </div>
                            </v-tooltip>
                        </p>
                        <p v-else>
                            -
                        </p>
                    </div>
                </div>

                <!-- Email -->
                <div class="extras-row_col">
                    <div>
                        <p class="grey--text mb-0 text-body-2">
                            Email:
                        </p>
                        <p
                            v-if="client.email"
                            class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        >
                            <span>{{ client.email }}</span>
                            <v-tooltip
                                v-if="client.email_verified_at"
                                top
                                color="primary"
                                class="ml-2"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="#59C74D"
                                        class="ml-1 information"
                                        style="cursor: pointer"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-check-circle
                                    </v-icon>
                                </template>
                                <div class="mb-1">
                                    <p
                                        class="mb-0"
                                        style="font-size: 16px"
                                    >
                                        Verified:
                                    </p>
                                    <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                        {{ $date.humanizeDate(client.email_verified_at) }}
                                    </p>
                                </div>
                            </v-tooltip>
                        </p>
                        <p v-else>
                            -
                        </p>
                    </div>
                </div>

                <!-- Social -->
                <div class="extras-row_col">
                    <div>
                        <p class="grey--text mb-0 text-body-2">
                            Social:
                        </p>
                        <div class="">
                            <v-icon v-if="client.social === 'facebook'">
                                mdi-facebook
                            </v-icon>
                            <v-icon v-else-if="client.social === 'google'">
                                mdi-google
                            </v-icon>
                            <span v-else>
                                —
                            </span>
                        </div>
                    </div>
                </div>

                <!-- Address -->
                <div class="extras-row_col">
                    <div>
                        <p class="grey--text mb-0 text-body-2">
                            Address:
                        </p>
                        <p
                            v-if="client.address"
                            class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        >
                            {{ client.country }}, {{ client.region }}, {{ client.city }}, {{ client.address }}, {{ client.zip }}
                        </p>
                        <p v-else>
                            —
                        </p>
                    </div>
                </div>
            </div>

            <div class="extras-row flex-column">
                <!-- row title -->
                <div class="d-flex align-center extras-row_col extras-row_col-title">
                    <div class="d-flex align-center">
                        <v-icon class="mr-4">
                            mdi-barcode
                        </v-icon>
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            orders
                        </span>
                    </div>
                </div>

                <!-- Completed -->
                <div
                    v-if="client.statistics_parsed"
                    class="extras-row_col"
                >
                    <p class="grey--text mb-0 text-body-2">
                        Completed:
                    </p>
                    <p
                        v-if="client.statistics_parsed.orders.orders_completed_count !== 0"
                        class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                    >
                        <router-link
                            :to="{
                                name: 'orders',
                                query: {
                                    status: 'COMPLETE',
                                    search_by: 'clientid',
                                    search_for: client.id
                                }
                            }"
                        >
                            <v-avatar
                                :color="statusBgColor('complete')"
                                size="25"
                            >
                                <span
                                    class="font-weight-regular text-body-2"
                                    :style="`color: ${statusTextColor('complete')}`"
                                >
                                    {{ client.statistics_parsed.orders.orders_completed_count }}
                                </span>
                            </v-avatar>
                        </router-link>
                    </p>
                    <p
                        v-else
                        class="mb-0 d-flex align-center"
                    >
                        —
                    </p>
                </div>

                <!-- In progress -->
                <div
                    v-if="client.statistics_parsed"
                    class="extras-row_col"
                >
                    <p class="grey--text mb-0 text-body-2">
                        In progress:
                    </p>
                    <p
                        v-if="client.statistics_parsed.orders.orders_in_progress_count !== 0"
                        class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                    >
                        <router-link
                            :to="{
                                name: 'orders',
                                query: {
                                    status: 'PENDING',
                                    search_by: 'clientid',
                                    search_for: client.id
                                }
                            }"
                        >
                            <v-avatar
                                :color="statusBgColor('inprogress')"
                                size="25"
                            >
                                <span
                                    class="font-weight-regular text-body-2"
                                    :style="`color: ${statusTextColor('inprogress')}`"
                                >
                                    {{ client.statistics_parsed.orders.orders_in_progress_count }}
                                </span>
                            </v-avatar>
                        </router-link>
                    </p>
                    <p
                        v-else
                        class="mb-0 d-flex align-center"
                    >
                        —
                    </p>
                </div>

                <!-- Unpaid -->
                <div
                    v-if="client.statistics_parsed"
                    class="extras-row_col"
                >
                    <p class="grey--text mb-0 text-body-2">
                        Unpaid:
                    </p>
                    <p
                        v-if="client.statistics_parsed.orders.orders_unpaid !== 0"
                        class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                    >
                        <router-link
                            :to="{
                                name: 'orders',
                                query: {
                                    status: 'UNPAID',
                                    search_by: 'clientid',
                                    search_for: client.id
                                }
                            }"
                        >
                            <v-avatar
                                :color="statusBgColor('UNPAID')"
                                size="25"
                            >
                                <span
                                    class="font-weight-regular text-body-2"
                                    :style="`color: ${statusTextColor('UNPAID')}`"
                                >
                                    {{ client.statistics_parsed.orders.orders_unpaid }}
                                </span>
                            </v-avatar>
                        </router-link>
                    </p>
                    <p
                        v-else
                        class="mb-0 d-flex align-center"
                    >
                        —
                    </p>
                </div>
            </div>

            <div class="extras-row flex-column">
                <!-- row title -->
                <div class="d-flex align-center extras-row_col extras-row_col-title">
                    <div class="d-flex align-center">
                        <v-icon class="mr-4">
                            mdi-account-multiple-check
                        </v-icon>
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            writers
                        </span>
                    </div>
                </div>
                <div
                    class="extras-row_col"
                    style="cursor:pointer"
                    @click.stop="openSidebar('preferred')"
                >
                    <p class="grey--text mb-0 text-body-2">
                        Preferred:
                    </p>
                    <p
                        v-if="client.preferred_writers.length"
                        class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                    >
                        <v-avatar
                            color="#27ae601a"
                            size="25"
                        >
                            <span class="font-weight-regular text-body-2 success--text">
                                {{ Number(client.preferred_writers.length) }}
                            </span>
                        </v-avatar>
                    </p>
                    <p
                        v-else
                    >
                        -
                    </p>
                </div>
                <div
                    class="extras-row_col"
                    style="cursor:pointer"
                    @click.stop="openSidebar('blocked')"
                >
                    <p class="grey--text mb-0 text-body-2">
                        Blocked:
                    </p>
                    <p
                        v-if="client.blocked_writers.length"
                        class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                    >
                        <v-avatar
                            color="#ff00001a"
                            size="25"
                        >
                            <span class="font-weight-regular text-body-2 error--text">
                                {{ Number(client.blocked_writers.length) }}
                            </span>
                        </v-avatar>
                    </p>
                    <p
                        v-else
                    >
                        -
                    </p>
                </div>
            </div>

            <div class="extras-row flex-column">
                <!-- row title -->
                <div class="d-flex align-center extras-row_col extras-row_col-title">
                    <div class="d-flex align-center">
                        <v-icon class="mr-4">
                            mdi-account-cog-outline
                        </v-icon>
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            settings
                        </span>
                    </div>
                </div>

                <!-- Phone calls -->
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        Phone calls:
                    </p>
                    <p
                        class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        :class="!client.chk_calls ? 'error--text' : 'success--text'"
                    >
                        <span>{{ Boolean(client.chk_calls) ? 'Yes': 'No' }}</span>
                        <information-tooltip
                            v-if="client.call_params"
                        >
                            <div class="mb-1">
                                <p class="mb-0 text-body-6">
                                    Time:
                                </p>
                                <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                    {{ $date.time(client.call_params.from) }} - {{ $date.time(client.call_params.to) }}
                                </p>
                            </div>
                            <div class="mb-1">
                                <p class="mb-0 text-body-6">
                                    Messengers:
                                </p>
                                <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                    <ul class="pl-0">
                                        <li
                                            v-for="(mes, index) in client.call_params.messenger"
                                            :key="index"
                                            style="list-style-type: none"
                                            class="pl-0"
                                        >
                                            <span
                                                class="mr-2"
                                                style="font-size: 11px"
                                            > {{ index + 1 }}.</span>
                                            <span>{{ mes }}</span>
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </information-tooltip>
                    </p>
                </div>
                <!-- SMS notifications -->
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        SMS notifications:
                    </p>
                    <p
                        class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        :class="!client.chk_sms_notifications ? 'error--text' : 'success--text'"
                    >
                        {{ Boolean(client.chk_sms_notifications) ? 'Yes': 'No' }}
                    </p>
                </div>

                <!-- Email marketing: -->
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        Email marketing:
                    </p>
                    <p
                        class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        :class="!client.chk_email_marketing ? 'error--text' : 'success--text'"
                    >
                        {{ Boolean(client.chk_email_marketing) ? 'Yes': 'No' }}
                    </p>
                </div>

                <!-- Email notifications: -->
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        Email notifications:
                    </p>
                    <p
                        class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        :class="!client.chk_email_notifications ? 'error--text' : 'success--text'"
                    >
                        {{ Boolean(client.chk_email_notifications) ? 'Yes': 'No' }}
                    </p>
                </div>
            </div>

            <div class="extras-row flex-column">
                <!-- row title -->
                <div class="d-flex align-center extras-row_col extras-row_col-title">
                    <div class="d-flex align-center">
                        <v-icon class="mr-4">
                            mdi-advertisements
                        </v-icon>
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            UTM
                        </span>
                    </div>
                </div>

                <!-- Campaign -->
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        Campaign:
                    </p>
                    <p
                        v-if="client.utm && client.utm.utm_campaign"
                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                    >
                        <span
                            class="d-inline-block text-truncate success--text"
                            style="max-width: 150px;"
                        >{{ client.utm.utm_campaign }}</span>
                        <information-tooltip>
                            <span>{{ client.utm.utm_campaign }}</span>
                        </information-tooltip>
                    </p>
                    <p v-else>
                        &mdash;
                    </p>
                </div>
                <!-- Campaign -->
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        Source:
                    </p>
                    <p
                        v-if="client.utm && client.utm.utm_source"
                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                    >
                        <span
                            class="d-inline-block text-truncate success--text"
                            style="max-width: 150px;"
                        >{{ client.utm.utm_source }}</span>
                        <information-tooltip>
                            <span>{{ client.utm.utm_source }}</span>
                        </information-tooltip>
                    </p>
                    <p v-else>
                        &mdash;
                    </p>
                </div>
                <!-- Medium -->
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        Medium:
                    </p>
                    <p
                        v-if="client.utm && client.utm.utm_medium"
                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                    >
                        <span
                            class="d-inline-block text-truncate success--text"
                            style="max-width: 150px;"
                        >{{ client.utm.utm_medium }}</span>
                        <information-tooltip>
                            <span>{{ client.utm.utm_medium }}</span>
                        </information-tooltip>
                    </p>
                    <p v-else>
                        &mdash;
                    </p>
                </div>
            </div>

            <div class="extras-row flex-column">
                <!-- row title -->
                <div class="d-flex align-center extras-row_col extras-row_col-title">
                    <div class="d-flex align-center">
                        <v-icon class="mr-4">
                            mdi-account-star
                        </v-icon>
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            Affiliate
                        </span>
                    </div>
                </div>
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        Active
                    </p>
                    <p
                        v-if="client.affiliate"
                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                        :class="!client.affiliate.is_active ? 'error--text' : 'success--text'"
                    >
                        <span>{{ Boolean(client.affiliate.is_active) ? 'Yes': 'No' }}</span>
                    </p>
                </div>
                <!-- Created -->
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        Created at
                    </p>
                    <p
                        v-if="client.affiliate && client.affiliate.created_at"
                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                    >
                        <span
                            class="d-inline-block text-truncate"
                            style="max-width: 150px;"
                        >{{ $date.dateFromNow(client.affiliate.created_at) }}</span>
                        <information-tooltip>
                            <span>{{ $date.humanizeDate(client.affiliate.created_at) }}</span>
                        </information-tooltip>
                    </p>
                    <p v-else>
                        &mdash;
                    </p>
                </div>
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        Rate
                    </p>
                    <p
                        v-if="client.affiliate && client.affiliate.rate"
                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                    >
                        {{ client.affiliate.rate }}%
                    </p>
                    <p v-else>
                        &mdash;
                    </p>
                </div>
                <div class="extras-row_col">
                    <p class="grey--text mb-0 text-body-2">
                        Min payout
                    </p>
                    <p
                        v-if="client.affiliate && client.affiliate.min_payout"
                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                    >
                        {{ client.affiliate.min_payout | money }}
                    </p>
                    <p v-else>
                        &mdash;
                    </p>
                </div>
            </div>
        </v-card>

        <v-card
            elevation="2"
            class="px-5 py-5 mt-10"
        >
            <v-row>
                <template>
                    <!--                    <v-tabs v-model="activeTab">-->
                    <!--                        <template v-for="tab in tabs">-->
                    <!--                            <v-tab-->
                    <!--                                :key="tab.path"-->
                    <!--                                :to="tab.path"-->
                    <!--                            >-->
                    <!--                                {{ tab.text }}-->
                    <!--                                <v-chip-->
                    <!--                                    v-if="tab.total >= 0"-->
                    <!--                                    class="ml-2 px-2"-->
                    <!--                                    label-->
                    <!--                                >-->
                    <!--                                    {{ tab.total | number }}-->
                    <!--                                </v-chip>-->
                    <!--                            </v-tab>-->
                    <!--                        </template>-->
                    <!--                    </v-tabs>-->
                    <select-tabs
                        v-model="activeTab"
                        :items="tabs"
                    />
                    <KeepAlive
                        :max="3"
                    >
                        <router-view
                            class="white col-12"
                            :credits="client.credits"
                            :client-id="$route.params.id"
                            :client-name="`${client.firstname} ${client.lastname}`"
                            :create-ticket-prepend="createTicketPrepend"
                        />
                    </KeepAlive>
                </template>
            </v-row>
        </v-card>

        <prefered-blocked-viewer
            v-if="showWriters"
            :items="getterWritersShortList"
            :title="writersTitle"
            @closeWriters="closeWriters"
        />

        <component
            :is="modalComponent"
            :title="modal.title"
            :description="modal.description"
            :clientid="$route.params.id"
            :current="createTicketPrepend"
            :client="client"
            :is-client="true"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';

import filtersMixin from '@/mixins/filtersMixin'

import { eventBus } from '@/helpers/event-bus/'

import Api from '@/helpers/api/index.js'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'

import ModalClientAffiliate from '@/components/shared/Modals/ModalClientAffiliate'
import ModalAddStoreCredit from '@/components/shared/Modals/ModalAddStoreCredit'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalClientEdit from '@/components/shared/Modals/ModalClientEdit'
import ModalCreateTicketClient from '@/components/shared/Modals/ModalCreateTicketClient'
import ModalCreateSMS from '@/components/shared/Modals/ModalCreateSMS'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard'
import { statusBgColor, statusTextColor } from '@/helpers/order/statusHelper'
import { nameCapitalized } from '@/helpers/capitalized/index'
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'

import {
    ACTION_GET_WRITER_SHORT_LIST
} from '@/store/modules/writers/action-types';

import {
    TOOLBARS_CLIENT
} from '@/constants/Client'

import {
    ACTION_ADD_STORE_CREDIT,
    ACTION_UPDATE_CLIENT,
    ACTION_REFRESH_STATISTIC,
    ACTION_CREATE_SMS,
    ACTION_CLIENT_AFFILIATE
} from '@/store/modules/clients/action-types';

import { CLEAR_CLIENT_LIST } from '@/store/modules/clients/mutation-types';

import {
    ACTION_GET_BATCH_LIST
} from '@/store/modules/batch/action-types';

import {
    ACTION_CREATE_TICKET
} from '@/store/modules/support/action-types';

import PreferedBlockedViewer from '@/components/SidePanels/PreferedBlockedViewer';
import InformationTooltip from '@/components/shared/InformationTooltip.vue';
import SelectTabs from '@/components/shared/SelectTabs.vue';

const {
    mapActions: mapClientsActions,
    mapMutations: mapClientsMutations
} = createNamespacedHelpers('clients');

const {
    mapActions: mapWritersActions
} = createNamespacedHelpers('writers');

const {
    mapActions: mapAdminActionsSupport
} = createNamespacedHelpers('support');

const {
    mapActions: mapBatchActions
} = createNamespacedHelpers('batch');

export default {
    components: {
        SelectTabs,
        InformationTooltip,
        AvatarRolles,
        ModalAddStoreCredit,
        ModalClientAffiliate,
        ModalClientEdit,
        ModalCreateTicketClient,
        WriterAvatar,
        CopyToClickBoard,
        PreferedBlockedViewer,
        DomainFavicon,
        TableRowsMenu,
        ModalAlert,
        ModalCreateSMS
    },
    mixins: [
        filtersMixin
    ],
    data() {
        return {
            client: null,
            activeTab: 'Orders',
            openedModal: '',
            modal: {},
            order: {},
            showWriters: false,
            writersTitle: ''
        }
    },
    computed: {
        ...mapGetters('writers', [
            'getterWritersShortList'
        ]),
        modalComponent() {
            return this.modal.name || ''
        },
        getClientId() {
            return this.$route.params.id
        },
        toolbars() {
            return TOOLBARS_CLIENT
        },
        tabs() {
            return [
                {
                    text: 'Orders',
                    total: this.client.counters.orders_count,
                    path: `/hr/clients/${this.getClientId}`
                },
                {
                    text: 'tickets',
                    total: this.client.counters.tickets_count,
                    path: `/hr/clients/${this.getClientId}/tickets`
                },
                {
                    text: 'friends',
                    total: this.client.statistics_parsed.friends.active,
                    path: `/hr/clients/${this.getClientId}/friends`
                },
                {
                    text: 'Billing',
                    total: null,
                    path: `/hr/clients/${this.getClientId}/billing`
                },
                {
                    text: 'Credits',
                    total: this.client.credits?.length || 0,
                    path: `/hr/clients/${this.getClientId}/credits`
                },
                {
                    text: 'Feedbacks',
                    total: this.client.counters.feedbacks_count || 0,
                    path: `/hr/clients/${this.getClientId}/feedbacks`
                }
            ]
        },
        getCountre() {
            return this.client.country?.toLowerCase()
        },
        getCountryLastSession() {
            return this.client.last_session?.ip_country?.toLowerCase()
        },
        createTicketPrepend() {
            return {
                domain: this.client.domain,
                client: {
                    id: this.client.id
                }
            }
        }
    },
    watch: {
        async getClientId() {
            await this.getClientData()
        }
    },
    beforeRouteLeave(to, from, next) {
        if (!to.name.includes('clients')) {
            this[CLEAR_CLIENT_LIST]()
        }
        next()
    },
    async activated() {
        await this.getClientData()
    },
    deactivated() {
        this.$destroy()
    },
    methods: {
        nameCapitalized,
        statusBgColor,
        statusTextColor,
        ...mapClientsActions({
            ACTION_ADD_STORE_CREDIT,
            ACTION_UPDATE_CLIENT,
            ACTION_REFRESH_STATISTIC,
            ACTION_CREATE_SMS,
            ACTION_CLIENT_AFFILIATE
        }),
        ...mapAdminActionsSupport({
            ACTION_CREATE_TICKET
        }),
        ...mapWritersActions({
            ACTION_GET_WRITER_SHORT_LIST
        }),
        ...mapBatchActions({
            ACTION_GET_BATCH_LIST
        }),
        ...mapClientsMutations({
            CLEAR_CLIENT_LIST
        }),
        async getClientData() {
            const { data } = await Api.get('/api/client/fetch-client', { id: this.getClientId })
            this.client = data;
            // await this[ACTION_GET_BATCH_LIST]({ search_for: this.getClientId, search_by: 'clientid' })
        },
        onToolbarActivated(payload) {
            this.actionName = payload.actionName
            this.modal = payload.modal
        },
        openSidebar(type) {
            let writers = []
            if (type === 'preferred') {
                writers = this.client.preferred_writers
                this.writersTitle = 'Preffered writers'
            }
            if (type === 'blocked') {
                writers = this.client.blocked_writers
                this.writersTitle = 'Blocked writers'
            }
            if (writers.length === 0) {
                return
            }
            this.showWriters = true
            this[ACTION_GET_WRITER_SHORT_LIST]({ writers })
        },
        closeWriters() {
            this.showWriters = false
        },
        // MODALS ACTION
        actionRow(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        async onConfirmModal(payload) {
            if (this.actionName === 'store_credit') {
                this.addClientStoreCredit({ ...payload, clientid: this.$route.params.id })
                return
            }
            if (this.actionName === 'edit') {
                this.editClient({ ...payload })
            }
            if (this.actionName === 'contact_client') {
                this.contactClient({ ...payload })
            }
            if (this.actionName === 'statistic_refresh') {
                await this.refreshStatistic(payload)
            }
            if (this.actionName === 'client_affiliate') {
                await this.clientAffiliate(payload)
            }
            if (this.actionName === 'create_sms') {
                await this.sendSMS({ ...payload, clientid: this.$route.params.id })
            }
        },
        async clientAffiliate(payload) {
            try {
                await this[ACTION_CLIENT_AFFILIATE](payload)
                eventBus.$emit('showSnackBar', 'Client affiliate is created.', 'success')
                await this.getClientData()
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async contactClient(payload) {
            try {
                await this[ACTION_CREATE_TICKET](payload)
                eventBus.$emit('showSnackBar', 'The support ticket is created.', 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async addClientStoreCredit(payload) {
            try {
                const data = await this[ACTION_ADD_STORE_CREDIT](payload)
                this.client.statistics_parsed.money.account_balance = `${data.balance}`
                eventBus.$emit('showSnackBar', 'success', 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async refreshStatistic() {
            try {
                await this[ACTION_REFRESH_STATISTIC]({ id: this.getClientId })
                await this.getClientData()
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async editClient(payload) {
            try {
                await this[ACTION_UPDATE_CLIENT]({ ...payload })
                eventBus.$emit('showSnackBar', 'Client info has been edit', 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async sendSMS(payload) {
            try {
                await this[ACTION_CREATE_SMS](payload)
                eventBus.$emit('showSnackBar', 'success', 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>

<style lang="scss">
.header-client {
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
}

.client-card{
    justify-content: end;
    gap: 30px;
    padding: 10px 0;
    @media all and (max-width: 768px) {
        justify-content: initial;
        overflow: scroll;
    }
}

.header-col {
    padding-right: 10px;
    border-right: 1px solid #d3d3d3;
}
</style>
